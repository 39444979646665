$slick-loader-path: "/_resources/themes/topsystems/images/";
$slick-font-path: "/_resources/themes/topsystems/webfonts/";
//$slick-font-family: "slick" !default;

@function toRem($value) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1600px,
        xxxl: 1724px
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1660px,
        xxxl: 1784px
);

$grid-gutter-width: 52px;
$tablet-height: 768px;
$desktop-height: 900px;

// Fonts
$font-family-sans-serif: 'Avenir', Arial, Helvetica, sans-serif;
$font-family-base: $font-family-sans-serif;
$font-family-medium: 'Avenir Medium', 'Avenir', Arial, Helvetica, sans-serif;
$font-family-light: 'Avenir Light', 'Avenir', Arial, Helvetica, sans-serif;

$h1-font-family: 'Avenir Black', 'Avenir', Arial, Helvetica, sans-serif;
$headings-font-family: $h1-font-family;
$headings-margin-bottom: 0.625rem; // 10px

$font-size-base: 1.125rem; // 18px
$line-height-base: 1.22;
$h1-font-size: 5.75rem; // 92px

$h2-font-size: 3.375rem; // 54px
$h3-font-size: 2rem; // 34px => 32px is better for fitting
$h4-font-size: 1.5rem; // 24px
$h5-font-size: 1.125rem; // 18px
$h6-font-size: 1rem; // 16px

// Z-indexes
$z-header: 10000;
$z-layover: 10010;
$z-navigation: 10013;
$z-hamburger: 10014;

//$link-color: cmyk(85,24,0,0);
//$top-menu-color: cmyk(5.098,0,0,45.098);

$corporateBlack: #212529;
$corporateBlue: #2f97da;
$blueShadeLight: #218AD2;
$blueShadeMedium: #2083CF;
$blueShadeDark: #1C6AC3;
$corporateRed: #fa5000;
$corporateGray: #EDEEEE;
$corporateGrayLogo: #a1a5a7;
$corporateGrayLogoStop: #696972;
$corporateGrayLogoHover: #2e2e31;

$corporateGreen: #006400;
$corporateOliveGreen: #556b2f;
$corporateMosGreen: #8b8b00;
$corporateSeaGreen: #2e8b57;
$corporateLightGreen: #1eb828;

$corporateGradientStart: #0492D4;
$corporateGradientStop: #046AC3;

$corporateButtonColor: $blueShadeLight;
$corporateButtonGradientStart: $blueShadeMedium;
$corporateButtonGradientStop: $blueShadeDark;

$white: #fff;
$black: $corporateBlack;
$blue: $corporateBlue;
$link-color: $blue;

$top-menu-color: #909090;

$blueSliderBackground: #cbe9f5;
$menuBg: rgb(161, 164, 167);

$secondary: #FFF;
$gray-400: #bbb;
$gray: $corporateGray;

// header
$navbar-nav-link-padding-x: toRem(14);

// button colors
$primary: $link-color;
$btn-border-radius: 26px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-width: 0;
$outline-width: 3px;

$btn-padding-x: 27px;
$btn-padding-x-sm: $btn-padding-x;
$btn-padding-y: 9px;

$btn-font-size: $font-size-base;
$btn-font-family: $h1-font-family;
$btn-line-height-sm: 1.5;
$btn-line-height: 1.5;

// dropdown menu
$dropdown-color:                    $blue;
$dropdown-link-color:               $blue;

// Settings hamburger
// ==================================================
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 14px !default;
$hamburger-layer-width: 32px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #000 !default;
$hamburger-layer-border-radius: 0 !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;
