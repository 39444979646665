/**
 * Flags from https://flagpedia.net/
 */
.flags {
  .flag-icon {
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: contain;
    overflow: hidden;
    text-indent: -9999px;
  }
}