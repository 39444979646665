body:not(.nav-hidden) {
  transition: padding-top 200ms ease;
  padding-top: 110px;

  //@include media-breakpoint-down(xs) {
  //  padding-top: 110px;
  //}

  @include media-breakpoint-up(xl) {
    padding-top: 150px;

    &.scrolled-down.has-scrolled {
      padding-top: 84px;
    }
  }
}

header.header {
  body:not(.nav-hidden) & {
    position: fixed;
    z-index: 90;
    left: 0;
    right: 0;
    top: 0;
    height: 110px;

    //@include media-breakpoint-down(xs) {
    //  height: 110px;
    //}

    @include media-breakpoint-up(xl) {
      transition: height 200ms ease;
      height: 150px;
    }
  }

  @include media-breakpoint-up(xl) {
    body:not(.nav-hidden).scrolled-down.has-scrolled & {
      height: 84px;
    }
  }

}

.navbar-collapse {
  @include media-breakpoint-down(lg) {
    background-color: $white;
    position: absolute;
    padding: 20px;
    top: 70px;
    right: 0;
    left: 0;
    z-index: 250;
  }

  li.nav-item {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
}

.navbar-brand {
  .header & {
    width: 200px;
    height: 90px;
    padding: 0;
    transition: ease 200ms width;

    @include media-breakpoint-up(sm) {
      width: 250px;
    }

    @include media-breakpoint-up(xl) {
      width: 215px;
    }
    @include media-breakpoint-up(xxl) {
      body:not(.scrolled-down) & {
        width: 416px;
      }
    }
  }
  //@include media-breakpoint-up(xxl) {
  //  body:not(.scrolled-down) .header & {
  //    height: 90px;
  //  }
  //}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

}

// hover on dropdown menu only on desktop
@include media-breakpoint-up(md) {
  .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    margin-top: 0;
  }

  .dropdown-toggle {
    margin-bottom: 2px;
  }

  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle {
    margin-bottom: 0;
  }
}

.navbar {
  .nav-item {
    display: inline-flex;
    align-items: center;
    @include h1font;
  }

  .dropdown-item {
    @include h1font;
  }
}

#mainMenu {
  .nav-link {
    outline: none;
  }

  .dropdown-menu {
    @include media-breakpoint-down(md) {
      border: 0;
    }
  }

  .nav-item {
    &:last-child {
      .btn {
        @include media-breakpoint-up(lg) {
          margin-left: 10px;
        }
      }
    }
  }
}

.top__line {
  transition: margin-top 200ms ease;
  overflow: hidden;
  margin-top: 10px;
  @include media-breakpoint-up(xl) {
    margin-bottom: 15px;
  }
  @include media-breakpoint-up(xxl) {
    margin-bottom: 22px;
  }

  .container {
    position: relative;
  }

  body.scrolled-down.has-scrolled & {
    margin-top: -30px;
    opacity: 0 !important;
  }
}

#topMenu {
  //height: 25px;
  //position: absolute;
  //right: 0;
  //top: 0;

  a {
    color: $top-menu-color;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .nav-item {
    font-weight: normal;
    font-family: $font-family-base;

    .nav-link {
      display: flex;
      align-items: center;

      img {
        height: 22px;
        margin-right: 9px;
      }
    }
  }

  .language__selector {
    border-left: 1px solid $top-menu-color;
    font-size: 9px;
    line-height: 1.5;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .dropdown-menu {
      padding: .8rem 1rem;
      left: -.3rem;
      min-width: 0;
    }

    button {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      background-color: transparent;
      border: 0;
      padding: .2rem .8rem;
      font-size: 9px;
      height: 24px;
      font-family: $headings-font-family;
      text-transform: uppercase;

      &:after {
        display: none;
      }
    }
  }

}

.main__line {
  padding-top: 0;
}

.mobile-buttons {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateY(50%);
  z-index: 8;

  > .container {
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}

.navbar-toggler {
  @include media-breakpoint-down(xs) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
