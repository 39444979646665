@import "mixins";

body {
  background-color: $blueShadeMedium;
}

.mb-60 {
  margin-bottom: 30px !important;
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px !important;
  }
}

.mt-60 {
  margin-top: 30px !important;
  @include media-breakpoint-up(lg) {
    margin-top: 60px !important;
  }
}

.pt-60 {
  padding-top: 30px !important;
  @include media-breakpoint-up(lg) {
    padding-top: 60px !important;
  }
}

.pb-60 {
  padding-bottom: 30px !important;
  @include media-breakpoint-up(lg) {
    padding-bottom: 60px !important;
  }
}

.mb-120 {
  margin-bottom: 60px !important;
  @include media-breakpoint-up(lg) {
    margin-bottom: 120px !important;
  }
}

.mt-120 {
  margin-top: 60px !important;
  @include media-breakpoint-up(lg) {
    margin-top: 120px !important;
  }
}

.pt-120 {
  padding-top: 60px !important;
  @include media-breakpoint-up(lg) {
    padding-top: 120px !important;
  }
}

.pb-120 {
  padding-bottom: 60px !important;
  @include media-breakpoint-up(lg) {
    padding-bottom: 120px !important;
  }
}

.container {
  &.container--narrow-gutter {
    .row {
      margin-right: -10px;
      margin-left: -10px;

      @if $grid-columns > 0 {
        @for $i from 1 through $grid-columns {
          .col-#{$i} {
            padding-left: 10px;
            padding-right: 10px;
          }
          @each $breakpoint, $container-max-width in $container-max-widths {
            .col-#{$breakpoint}-#{$i} {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-40 {
    flex: 0 0 calc(50% - 75px);
    max-width: calc(50% - 75px);
  }
  .col-lg-60 {
    flex: 0 0 calc(50% + 75px);
    max-width: calc(50% + 75px);
  }
  .col-lg-30 {
    flex: 0 0 calc(50% - 100px);
    max-width: calc(50% - 100px);
  }
  .col-lg-70 {
    flex: 0 0 calc(50% + 100px);
    max-width: calc(50% + 100px);
  }

  // for fitting to a col-lg-40 above
  .col-lg-50 {
    flex: 0 0 calc(50% - #{$grid-gutter-width / 2});
    max-width: calc(50% - #{$grid-gutter-width / 2});
  }

}

.text-orange {
  color: $corporateRed;
}

.container-fluid {
  max-width: 1600px;
}

header.header,
#mainContainer {
  background-color: #FFF;
}

#mainContainer {
  //padding-bottom: 80px;

  .HomePage &,
  .ContactPage & {
    padding-bottom: 0;
  }
}

.captionImage {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.with--ico {
  img {
    display: inline-block;
    width: auto;
    height: 34px;
    margin-right: 10px;
    vertical-align: middle;
    object-fit: contain;
  }
}

.bg-gray {
  background-color: #eceded;
}

.page__header {
  background: no-repeat center center #0492d4;
  background-size: cover;
  background-blend-mode: multiply;
  background-image: url('/_resources/themes/topsystems/images/header-mobile.jpg');
  color: $white;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;

  @include media-breakpoint-up(md) {
    background-image: url('/_resources/themes/topsystems/images/header.jpg');
    height: 500px;
  }

  body.Sector &,
  body.SolutionOverview &,
  body.Showcase &,
  body.SectorOverview & {
    background-image: url('/_resources/themes/topsystems/images/header2-mobile.jpg');
    @include media-breakpoint-up(md) {
      background-image: url('/_resources/themes/topsystems/images/header2.jpg');
      height: 500px;
    }
    @include media-breakpoint-up(lg) {
      height: 700px;
    }

  }
}

.absolute-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.blocks__even {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

}

.w-md-1\/5 {
  flex-basis: 20%;
}


.block--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.container-has-bottom-line .container,
.has-bottom-line {
  @include media-breakpoint-up(sm) {
    position: relative;
    border-bottom: 1px solid $black;
    padding-bottom: 50px;

    &:before, &:after {
      position: absolute;
      content: '';
      bottom: -7px;
      width: 1px;
      height: 15px;
      z-index: 9;
      border-left: 1px solid $black;
    }

    &:before {
      left: -1px;
    }

    &:after {
      right: -1px;
    }
  }

}

.container-has-bottom-line {
  + .outer-row:not(.gray):not(.blue) {
    padding-top: 0;
  }
}

.has-top-line {
  position: relative;
  border-top: 1px solid $black;
  padding-top: 50px;

  &:before, &:after {
    position: absolute;
    content: '';
    top: -7px;
    width: 1px;
    height: 15px;
    z-index: 9;
    border-left: 1px solid $black;
  }

  &:before {
    left: -1px;
  }

  &:after {
    right: -1px;
  }

}

div.clamp + [data-readmore-toggle], div.clamp[data-readmore] {
  display: block;
  width: 100%;
}

div.clamp[data-readmore] {
  transition: height 75ms;
  overflow: hidden;
}

.centered-block {
  text-align: center;

  p:last-child {
    margin-bottom: 0;
  }
}

.sticky__title {

}

.page__centered-block {
  text-align: center;
  //margin-bottom: 30px;
  //@media (min-height: $desktop-height) {
  //  margin-bottom: 60px;
  //}

  p:last-child {
    margin-bottom: 0;
  }
}

.page__centered-block--medium {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

.page__centered-block--large {
  @include media-breakpoint-up(md) {
    margin-left: 110px;
    margin-right: 110px;
  }
}

.page__centered-block--content {
  position: relative;
  padding: 0 20px;

  @include media-breakpoint-up(md) {
    min-height: 150px;
    padding: 0 40px;
  }

  .page__title {
    line-height: 40px;
  }
}

.page__title {
  margin-bottom: 24px;
}

.page__title--blue {
  color: $blueShadeDark;
}

.clamp-holder {
  &.opening {
    .page__centered-block--content {
      height: auto;
    }
  }

  &.opened {
    .page__centered-block--content {
      height: auto;
    }
  }

  a[data-readmore-toggle] {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
}

.table__holder {
  margin-top: 20px;
  margin-bottom: 20px;

  &.no-image {
    margin-top: 0;
  }

  table {
    td + td,
    td + th,
    th + td {
      padding-left: 20px;
    }
  }

  a {
    color: $body-color;
    text-decoration: underline;
  }
}

.table--striped {
  td + td,
  td + th,
  th + td {
    padding-left: 20px;
  }

  td, th {
    padding: 4px 7px;
  }

  tr {
    &:nth-child(odd) {
      background-color: $blueSliderBackground;
    }
  }

}

.image--aside {
  margin: 0 0 20px;
  @include media-breakpoint-up(md) {
    width: 50%;
    float: right;
    margin: 0 0 20px 20px;
  }
  @include media-breakpoint-up(lg) {
    width: 40%;
    margin: 0 0 40px 40px;
  }
}
