/* purgecss start ignore */
/* GENERIC FORMS
----------------------------------------------- */
div[id$="Url_Holder"],
div[id$="Comment_Holder"] {
  position: absolute;
  left: -6000px;
  top: -6000px;
}

form {
  // default forms are always rows
  input[type=checkbox] {
    width: 20px;
    height: 20px;
    border-color: $black;

    &:checked {
      border-color: $blue;
    }
  }

  .two--columns {
    div.middleColumn ul {
      @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        li {
          white-space: nowrap;
        }
      }
    }
  }

  div.field {
    + .field:not(.col-12) {
      margin-top: 30px;
    }
  }

  &.form--horizontal {
    div.field + .field {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .field.nocaptcha {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .full {
    width: 100%;
  }

  fieldset + .btn-toolbar, .fieldset + .btn-toolbar {
    margin-top: 10px;
  }

  .form--alert {
    color: $corporateRed;
  }

  div.vuetext.field, div.vuetextarea.field {
    position: relative;

    &.group--vuelid {
      //&:after {
      //  content: '';
      //  display: block;
      //  width: 20px;
      //  height: 20px;
      //  background-color: $blue;
      //  position: absolute;
      //  right: 10px;
      //  bottom: 7px;
      //  border-radius: 2px;
      //  z-index: 1;
      //}

      //&:before {
      //  display: inline-block;
      //  position: absolute;
      //  right: 10px;
      //  bottom: 7px;
      //  content: "\2714";
      //  font-size: 22px;
      //  line-height: 20px;
      //  color: white;
      //  z-index: 2;
      //}

    }
  }

  div.vuetextarea.field {
    position: relative;

    &.group--vuelid {
      &:before, &:after {
        right: 10px;
        bottom: 14px;
      }
    }
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  label:not(.form-check-label) {
    @include h1font;
    margin-bottom: 5px;

    &.left {
      display: block;
    }

    &.right {
      font-size: 12px;
    }
  }

  input {
    &[type="text"],
    &[type="date"],
    &[type="time"],
    &[type="url"],
    &[type="password"],
    &[type="email"],
    &[type="number"],
    &[type="tel"] {
      border-radius: 25px;
      width: 100%;
      font-size: $font-size-base;
      line-height: 1em;
      padding: 11px 20px;
      border: 3px solid $black;

      &.error,
      &.holder-required {
        border: 1px solid $red;
      }

      &.vuelid {
        border: 3px solid $blue;
      }
    }

    &::placeholder {
      color: #7d7d7d;
    }

    &:focus {
      outline: none;
    }

    &[disabled] {
      color: #ccc;
      background-color: #f7f7f7;
      border: 1px solid #dad9d9 !important;
    }
  }

  textarea,
  select {
    width: 100%;
    padding: 11px 20px;
    border: 3px solid $black;
    border-radius: 25px;
    background-color: #fff;
    font-size: $font-size-base;
    line-height: 15px;
    outline: 0;

    &.error,
    &.holder-required {
      border: 1px solid $red;
    }

    &.vuelid {
      border: 3px solid $blue;
    }
  }

  select {
    border: 3px solid $black;
    width: 100%;

    option.disabled {
      color: #ccc;
    }
  }

  textarea {
    resize: vertical; /* forces text area to be resized vertically only */
  }

  /* Radio and Checkbox */
  .field {
    ul.optionset {
      padding-left: 0;

      li {
        list-style: none;
      }

      &.option__labels {
        li {
          display: flex;

          + li {
            margin-top: 8px;
          }

          label {
            position: relative;

            font-family: $font-family-base;
            font-weight: normal;
            width: calc(100% - 60px);
          }
        }
      }

      &.option__labels.two--columns {
        margin-top: -5px;

        li {
          margin-top: 5px;
        }
      }
    }

    &.checkbox, &.radio {
      display: flex;
      flex-wrap: wrap;
    }

    &.checkbox {
      align-items: center;
    }

    input.checkbox, input.radio {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      padding: 0;
      flex-shrink: 0;
      flex-grow: 0;
    }

    input.checkbox {
      background-color: $white;
      border-radius: 8px;
    }

    input[type=checkbox] + label {
      display: block;
      cursor: pointer;
      position: relative;
      line-height: 24px;
      padding: 0 0 0 39px;
      margin: 0;
    }

    &.checkbox {
      input[type=checkbox] + label {
        height: 24px;
      }
    }

    input[type=checkbox] {
      display: none;

      + label:before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        border: 1px solid $black;
        //border-radius: 8px;
        width: 20px;
        height: 20px;
        transition: .2s;
      }

      //+ label:after {
      //  display: inline-block;
      //  position: absolute;
      //  left: -1px;
      //  top: -11px;
      //  content: "\2714";
      //  font-size: 34px;
      //  line-height: 34px;
      //  color: transparent;
      //}

      + label:active:before {
        transform: scale(0.8);
      }

      //+ label:active:after {
      //  opacity: 0.75;
      //}

      &:checked + label:before {
        border-color: $blue;
        background-color: $blue;
      }

      //&:checked + label:after {
      //  color: $blue;
      //}

      &:disabled + label:before {
        transform: scale(1);
        border-color: #aaa;
      }

      &:checked:disabled + label:before {
        transform: scale(1);
        background-color: $input-disabled-bg;
        border-color: $input-disabled-bg;
      }
    }

    input[type=radio] {
      appearance: none;
      display: inline-block;
      border: 1px solid $black;
      border-radius: 10px;
      width: 20px;
      height: 20px;
      transition: .2s;
      margin-right: 10px;

      + span {
        margin-left: 10px;
      }

      &:active {
        transform: scale(0.8);
      }

      &:checked {
        border-color: $blue;
        background-color: $blue;
      }

      &:disabled  {
        transform: scale(1);
        border-color: #aaa;
      }

    }

  }

  .checkbox label.right,
  .radio label.right {
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  /* Messages */
  .message {
    font-size: 14px;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    background-color: #fef4ba;
    padding: 5px 10px;
    border: 1px solid #e8c805;
    border-radius: 3px;

    .black & {
      color: #000;
    }
  }

  .good {
    background-color: #ecf9d0;
    border-color: #8fbe00;
  }

  .bad, .required {
    background-color: #f9d0d0;
    border-color: #cf0000;
    color: #b80000;
  }

  /* ACTIONS */
  .btn-toolbar {
    margin-bottom: 40px;
    align-items: center;
  }

  //input[type="submit"], .btn-toolbar .action {
  //  display: inline-block;
  //  letter-spacing: 1.5px;
  //  border: none;
  //  padding: 8px 15px;
  //  font-size: 14px;
  //  line-height: 15px;
  //  background-color: $blueShadeDark;
  //  color: $white;
  //  border-radius: 8px;
  //  font-family: $btn-font-family;
  //}

  input[type="reset"], button[type="reset"], .btn-toolbar .action[type="reset"] {
    padding-right: $btn-padding-x !important;

    &:after {
      display: none; // hide arrow
    }

    //font-weight: $font-weight-bold;
    //border: 1px solid $blueShadeDark;
    //background-color: $white;
    //color: $blueShadeDark;
  }

  /* AREA SPECIFIC */
  /* LOGIN and FORGOT PASSWORD */
  #Remember {
    min-height: 20px;
  }

  #ForgotPassword {
    clear: left;
    margin-bottom: 0;
    margin-left: 20px;
    font-size: 12px;
  }

  #MemberLoginForm_LostPasswordForm .btn-toolbar:after {
    display: none;
  }

}

div.form__row {
  display: flex;
  flex-wrap: wrap;

  > div.field {
    float: none;
  }

  > div.holder,
  &:not(.form__row--simple) > div.field {
    width: auto;
    flex: 1;
  }

  &.form__row--simple > div.field {
    flex: 1;
  }

  > .holder,
  > .field {
    transition: 0.4s ease border-left;
  }

  > label {
    margin-bottom: 0;
    padding-right: 0;
    width: 170px;
    min-width: 170px;
    max-width: 170px;
    display: inline-flex;
    align-items: center;
  }

  div.checkbox {
    label {
      display: block;
      font-size: 14px;
      line-height: 24px;
    }
  }

  + .form__row {
    margin-top: 30px;
  }

  &.form__row--simple {
    div.field {
      + .field {
        margin-top: 2px;
        @include media-breakpoint-up(md) {
          margin-left: 5px;
          margin-top: 0;
        }
      }
    }
  }

  &.form__row--no-stack {
    div.field {
      + .field {
        margin-top: 0;
        margin-left: 5px;
      }
    }
  }

  // nested compositefield
  &.CompositeField {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    > div.field,
    > p.field,
    > a {
      width: auto;
      position: relative;
      flex-grow: 1;

      &.half {
        @include media-breakpoint-up(md) {
          flex-grow: 0;
          flex-basis: calc(50% - 15px); // subtract the half of 30px margin
        }
      }
    }

    > a {
      text-indent: 20px;
    }

    div.field {
      + .field {
        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-left: 30px;
        }
      }
    }
  }
}


/* purgecss end ignore */
