.section__footer {
  position: relative;
  //font-family: $font-family-light;
  background-color: $corporateBlue;
  color: $white;
  padding: 40px 0;

  @include media-breakpoint-up(xxl) {
    padding: 130px 0;
  }

  a {
    color: $white;
  }

  .navbar-brand {
    margin-top: -24px;
    width: 100%;
    height: 150px;

    img {
      object-position: left;
    }

    @include media-breakpoint-up(xxl) {
      margin-bottom: 20px;
      width: 525px;
      height: 172px;
    }
  }
}

.footer__description {
  max-width: 525px;

  a {
    text-decoration: underline;
  }
}

.footer-block__title {
  font-size: $font-size-base;
  margin-bottom: 22px;
}

.footer-block__content {
  ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li li {
      margin-left: 19px;
    }
  }
}

.col-newsletter {
  @include media-breakpoint-up(lg) {
    //max-width: 300px;
    //margin-left: auto;
  }
}

#mc_embed_signup {
  #mc_embed_signup_scroll {
    display: flex;
    flex-direction: column;
    //@include media-breakpoint-up(xl) {
    //  flex-direction: row;
    //}
  }

  input {
    border: 0;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  input.email {
    color: $white;
    background-color: $corporateBlue;
    font-size: 16px;
    padding: 8px 10px;
    margin-bottom: 10px;
    max-width: 250px;
    border: 3px solid $white;
    border-radius: 20px;

    &::placeholder {
      color: $white;
      font-weight: bold;
    }
  }

  input.button {
    background-color: $black;
    color: $white;
    //width: 100%;
    border-radius: 5px;
    flex-shrink: 0;
    flex-basis: 100px;
    min-width: 150px;
  }
}

.social-links {
  display: flex;
  //width: calc(100% - 100px);
  //justify-content: space-between; // when the newsletter input is small
  //@include media-breakpoint-up(sm) {
  //  justify-content: flex-start; // newsletter input is fullpage, do not strecht
  //}
  //@include media-breakpoint-up(xxl) {
  //  justify-content: space-between; // when the newsletter input is small again
  //}
}

.social__link {
  display: block;
  background: no-repeat 0 0 $white;
  background-size: contain;
  text-indent: -999px;
  overflow: hidden;
  width: 56px;
  //height: 56px;
  border-radius: 28px;

  &:after { // responsive square
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  + .social__link {
    margin-left: 10px;
  }
}

.social__link--inline {
  display: inline-block;
  vertical-align: bottom;
  background: no-repeat 0 0 $white;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.social__link--facebook {
  background-image: url("/_resources/themes/topsystems/images/social-facebook.svg");
}

.social__link--linkedin {
  background-image: url("/_resources/themes/topsystems/images/social-linkedin.svg");
}

.social__link--instagram {
  background-image: url("/_resources/themes/topsystems/images/social-instagram.svg");
}

.social__link--youtube {
  background-image: url("/_resources/themes/topsystems/images/social-youtube.svg");
}

.social__link--twitter {
  background-image: url("/_resources/themes/topsystems/images/social-twitter.svg");
}

.footer-border--left {
  position: relative;

  &-sm {
    @include media-breakpoint-up(sm) {
      padding-left: 30px;
      border-left: 1px solid white;
    }
  }

  &-md {
    @include media-breakpoint-up(md) {
      padding-left: 30px;
      border-left: 1px solid white;
    }
  }

  &-lg {
    @include media-breakpoint-up(lg) {
      padding-left: 30px;
      border-left: 1px solid white;
    }
  }

  &:before {
    content: '';
  }
}

.bottom {
  padding-top: 25px;
  padding-bottom: 25px;
  //font-family: $font-family-light;

  &, a {
    color: $white;
  }

  .bottom__menu {
    text-align: right;

    a {
      + a {
        margin-left: 22px;
      }
    }
  }
}

.newsletter__holder {
  position: relative;

  .newsletter__submit {
    position: absolute;
    text-indent: -9999px;
    overflow: visible;
    right: 0;
    top: 0;
    z-index: 2;
    border: none !important;
    &:hover {
      &:after {
        border-color: $white;
      }
    }
  }
}

._form_5 {
  form {
    padding: 0 !important;
    margin: 0;
    ._form_element label {
      font-size: 1.125rem !important;
    }

    #email {
      color: $white !important;
      background-color: $corporateBlue !important;
      font-size: 16px !important;
      padding: 8px 10px !important;
      margin-bottom: 10px !important;
      max-width: 250px !important;
      border: 3px solid $white !important;
      border-radius: 20px !important;

      &::placeholder {
        color: $white;
        font-weight: bold;
      }
    }

    ._button-wrapper > button._submit {
      border-radius: 20px !important;
      padding: 7px 10px !important;
      font-family: $font-family-base !important;
    }
  }

}
