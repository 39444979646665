.event__filters {
  a {
    &.active {
      color: $black;
    }
  }
}

.single__event {
  h3 {
    font-size: 18px;
    line-height: 1.1;
    font-family: $font-family-base;
    font-weight: normal;
    margin-bottom: 20px;

    a {
      color: $corporateBlue !important;
    }
  }
}

.fit--image {
  position: relative;

  .dates, .training__status {
    color: $white;
    background-color: $blueShadeDark;
    padding: 8px;
    font-family: $headings-font-family;
  }

  .dates.abs {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .training__status.abs {
    position: absolute;
    left: 0;
    top: 0;
  }
}
