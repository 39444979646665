.single__job {
  h3 {
    font-size: 18px;
    line-height: 1.1;
    font-family: $font-family-base;
    font-weight: normal;
    margin-bottom: 20px;

    a {
      color: $corporateBlue !important;
    }
  }
}
