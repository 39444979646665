#declineCookie {
  display: none;
}

.cookie_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inner_buttons {
    display: flex;
    align-items: center;
  }
}

.home--image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;

  @include media-breakpoint-up(md) {
    @media screen and (min-height: 800px) {
      height: 600px;
    }
  }

  @include media-breakpoint-up(lg) {
    @media screen and (min-height: 650px) {
      height: calc(100vh - 250px);
    }

    @media screen and (min-height: 900px) {
      height: 700px;
    }

    @media screen and (min-height: 1000px) {
      height: 800px;
    }
  }

  background-size: cover;
  background-color: rgba(4, 146, 212, 1);
  background-blend-mode: multiply;

  //@include ie10 {
  //  background-blend-mode: unset;
  //  &:before {
  //    content: "";
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    height: 100%;
  //    width: 100%;
  //    opacity: 0.8;
  //    background-color: rgba(4,146,212, 0.5);
  //  }
  //}

  h1 {
    display: block;
    text-align: center;
    padding: 3px 8px;
    color: $white;
    margin: 0;
  }
}

.fit--image {
  img {
    width: 100%;
    height: auto;
  }
}

.cover--image {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  div.row &.col-lg-6:first-child {
    padding-left: 0;
    padding-right: 0;
  }
}

.client__logo {
  flex-grow: 0;
  height: 60px;

  @include media-breakpoint-up(md) {
    height: 110px;
  }

  img {
    object-fit: contain;
  }
}

body.HomePage,
body.SolutionOverview,
body.Sector {
  .solutions-holder {
    padding-top: 0;
    margin-top: -60px;

    @include media-breakpoint-up(md) {
      margin-top: -100px;
    }

    + .markets-holder {
      padding-top: 0;
    }
  }
}

body.SectorOverview {
  .markets-holder {
    padding-top: 0;
    margin-top: -60px;

    @include media-breakpoint-up(md) {
      margin-top: -100px;
    }
  }
}

.negative-margin-top {
  padding-top: 0;
  margin-top: -60px;

  @include media-breakpoint-up(md) {
    margin-top: -100px;
  }
}

.title-icon-holder {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  .icon--small {
    flex-shrink: 0;
    margin-bottom: 8px;
  }

  h2 & {
    font-size: $h3-font-size;
    word-break: break-word;
  }

  &.title-icon-holder--smaller {
    font-size: $h4-font-size;
  }
}

.blue-header__block {
  background-color: $white;
  border: 1px solid $white;

  .bg-gradient--main.block--padding {
    &.block--narrow, .block--narrow & {
      padding: 40px 20px;
    }
  }

  .bg-gradient--main {
    @include media-breakpoint-up(lg) {
      min-height: 185px;
    }

    h2 {
      margin-bottom: 0;
      word-break: break-word;
    }

    &, a {
      color: $white;
    }

    @include media-breakpoint-only(md) {
      min-height: 220px;
    }
  }

  .block--excerpt {
    p:last-of-type {
      @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
      }
    }
  }

}

.blue__block {
  background: rgb(4, 146, 212);
  color: $white;

  a:not(.btn) {
    color: $white;
  }

  h2 {
    > a {

    }
  }

  ul {
    padding-left: 19px;
    font-size: 13px;
    line-height: 1.61em;

    a {
      display: block;

      &:hover {
        color: $black;
      }
    }

  }

}

//.block--narrow {
//  h2 {
//    .icon--inline {
//      width: 30px;
//      height: 30px;
//    }
//  }
//}

.bg-gradient--main {
  background: radial-gradient(at 120% 50%, rgba(4, 146, 212, 1) 0%, rgba(4, 106, 195, 1) 100%);

}

.markets-holder {
  //background: linear-gradient(to bottom, $gray 0%, $gray 50%, $white 50%);
}


/** --------- HIGHLIGHTED --------- */

.block--vertical-centered {
  display: flex;
  //align-items: center;
  justify-content: center;
  height: 100%;
}

.block--vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

//.highlighted__block--text {
//  > h2 {
//    @include media-breakpoint-only(xs) {
//      font-size: 1.6rem;
//      word-break: break-word;
//    }
//  }
//}
//
.highlighted__block--image {
  display: flex;
  align-items: center; // for the image vertically centered
  justify-content: center;

  @include ie10 {
    display: block;
  }
}

/** --------- MARKETS --------- */
.icon {
  &--blue {
    img {
      fill: $blue;
    }
  }

  &--small {
    img {
      object-fit: contain;
      height: 34px;

      + img {
        margin-left: 40px;
      }
    }

  }

  &--small-40 {
    img {
      object-fit: contain;
      height: 44px;
    }

  }

  &--small-medium {
    img {
      object-fit: contain;
      height: 61px;
    }
  }

  &--medium {
    img {
      object-fit: contain;
      height: 100px;
    }
  }

  &--inline {
    display: inline-block;
    width: 24px;
    height: 100%;

    @include media-breakpoint-up(xl) {
      width: 60px;
    }
  }
}

blockquote.review--text {
  margin: 30px 0 10px 0;
  font-family: $headings-font-family;
  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size;
  }
}

.review--company {
  margin-bottom: 35px;
  text-align: center;
}

.stars {
  display: flex;
  justify-content: center;
  margin: 0;

  .star {
    width: 30px;
    height: 30px;
    background: transparent no-repeat 0 0;
    background-size: contain;
    background-image: url("/_resources/themes/topsystems/images/bliksem_blauw_rgb_filled.svg");

    &.no {
      background-image: url("/_resources/themes/topsystems/images/bliksem_blauw_rgb.svg");
    }
  }

  & + .star {
    margin-left: 5px;
  }
}

.whitepaper__block--text {
  font-size: 13px;
}

.whitepaper__block--image {
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.whitepaper__form-holder {
  //width: calc(100% - 40px);
  //background-color: $gray;
  //color: $black;
  //padding: 60px;
  //margin: 20px;
  //
  //@include media-breakpoint-up(md) {
  //  margin: 20px 20px 20px 0;
  //  width: 340px;
  //}

  @include media-breakpoint-up(xxl) {
    h3 {
      width: 450px;
    }
  }

  form {
    div.field {
      + .field {
        margin-top: 20px;
      }
    }

    .btn-toolbar {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}

.whitepaper__block-holder {
  input[type="submit"], .btn-toolbar .action {
    width: 100%;
  }
}

/***---------------- OLD -------


.market__block--intro {
  text-align: center;
  margin: 0 auto;
  max-width: 300px;

  h2 {
    max-width: 290px;
  }
}

.market__block {
  display: block;
  background-color: $blueShadeLight;

  &.Pos2 {
    background-color: $blueShadeMedium;
  }

  &.Pos3 {
    background-color: $blueShadeDark;
  }
}

.market__block--link {
  h2 {
    @include transition($btn-transition);
  }

  &:hover {
    text-decoration: none;

    h2 {
      color: $black;
    }
  }
}

.market__block--image {
  padding-top: 100%;
  background-size: cover;
}

.market__block--text {
  padding: 20px 15px;

  h2 {
    font-size: 20px;
    margin: 0;
    color: $white;
    // todo white button
  }
}

// --------- SHOWCASES ---------
.case__block--intro {
  text-align: center;
  margin: 0 auto;
  max-width: 300px;

  h2 {
    max-width: 290px;
  }
}

.case__block {
  display: block;
}

.case__block--text {
  display: flex;
  transform: translateY(-50%);

  .case__block--inner {
    @include transition($btn-transition);

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  .case__block--inner {
    display: block;
    color: $white;
    background-color: $blueShadeDark;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 70px;
    text-align: center;

    h2 {
      font-family: $font-family-base;
      font-size: 20px;
      line-height: 30px;
      margin: 0;

      strong {
        @include h1font;

      }

    }
  }
}

.case__block--image {
  padding-top: 500px;
  background-size: cover;
}

.case__block-holder {
}

// --------- CONTACT BLOCK ---------

.background-image-holder {
  background-size: cover;
  color: $white;
  text-align: center;
  background-position: center;
}

.background-image-holder--fixed {
  // other than IOS
  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed;
  }
}

.contact__block--intro {
  display: flex;
  min-height: 600px;
  height: 50vh;
  justify-content: center;
  flex-direction: column;
}

// --------- GENERAL BLOCK ---------
.general__block-holder {
}

.block__link {
  &:hover {
    text-decoration: none;
  }
}

*/
