@import 'theming';

h1 {
  @include h1font;
}

.h1--font {
  @include h1font;
}

.growing-h1 {
  font-size: $h1-font-size * 0.3;
  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size * 0.4;
  }
  @include media-breakpoint-up(md) {
    font-size: $h1-font-size * 0.5;
  }
  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size * 0.6;
  }
  @include media-breakpoint-up(xl) {
    font-size: $h1-font-size * 0.75;
  }
  @include media-breakpoint-up(xxl) {
    font-size: $h1-font-size;
    line-height: 1.228;
  }

}

@mixin growingh2 {
  & {
    font-size: $h2-font-size * 0.5;
    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size * 0.6;
    }
    @include media-breakpoint-up(xl) {
      font-size: $h2-font-size * 0.75;
    }
    @include media-breakpoint-up(xxl) {
      font-size: $h2-font-size;
    }
  }
}

@mixin growingh3 {
  font-size: $h3-font-size * 0.75;
  @include media-breakpoint-up(sm) {
    font-size: $h3-font-size * 0.85;
  }
  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
  }
}

@mixin growingh4 {
  font-size: $h4-font-size * 0.75;
  @include media-breakpoint-up(sm) {
    font-size: $h4-font-size * 0.85;
  }
  @include media-breakpoint-up(md) {
    font-size: $h4-font-size;
  }
}

.growing-h2,
.cms__content .growing-h2 {
  @include growingh2;
}

.growing-h3,
.cms__content h2:not(.growing-h1),
.cms__content h2:not(.growing-h3),
.cms__content h2:not(.growing-h4) {
  @include growingh3;
}

.growing-h4 {
  @include growingh4;
}


h3, .h3 {
  font-weight: 900;
}

h4, .h4 {
  font-weight: 900;
}

h5, .h5 {
  font-weight: 900;
}

h6, .h6 {
  font-weight: 900;
}

.font--base {
  font-size: $font-size-base;
}

.font--bigger {
  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}

.font--increase {
  font-size: 130%;
}

.font--smaller {
  font-size: 13px;
}

.font-regular {
  font-family: $font-family-base;
}

.font--black {
  @include h1font;
}

div.ss-htmleditorfield-file.embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  width: 100%;
  overflow: hidden;
  max-width: 100% !important;

  @include media-breakpoint-up(md) {
    padding: 0;
    width: 640px;
    height: 360px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.video--basic {
    @include media-breakpoint-up(lg) {
      width: 720px;
    }
  }

  &.video--full {
    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 100%;
      min-height: 612px;
    }
    @include media-breakpoint-up(xxl) {
      min-height: 871px;
    }
  }
}

.cms__content {
  > div, p {
    + p, + div, + h1, + h2, + h3, + h4, + h5, + h6 {
      margin-top: 1rem;
    }
  }

  h2, .h2 {
    word-wrap: break-word;
  }

  h3, .h3 {
    font-size: $h5-font-size;
    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
    }
  }

  h4, .h4, h5, .h5 {
    font-size: $font-size-base;
  }

  h6, .h6 {
    font-size: $h6-font-size;
  }

  + .btn {
    margin-top: 20px;
  }
}

.bg-blue {
  a:not(.btn) {
    color: $white;
    text-decoration: underline;
  }
}

.text-blue {
  color: $blue;
}

ul {
  &.two-columns {
    @include media-breakpoint-up(lg) {
      columns: 2;

      li {
        break-inside: avoid;
      }
    }
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.simple__link {
  text-align: center;
  text-decoration: underline;
  color: $black;
}

.page--prev {
  display: inline-block;
  margin-right: 20px;
}

.page--next {
  display: inline-block;
  margin-left: 20px;
}

.simple__link--center {
  display: block;
  text-align: center;
  text-decoration: underline;
  color: $black;
}

.sticky__title {
  position: sticky;
  top: 0;

  @include media-breakpoint-up(xxl) {
    margin-top: -12px;
    padding-right: 60px;
  }

  body:not(.nav-hidden).scrolled-down.has-scrolled & {
    @include media-breakpoint-up(lg) {
      top: 140px;
    }
    @include media-breakpoint-up(xl) {
      top: 90px;
    }
  }

}

.visually-hidden {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  left: -200vw;
}
