.alt-btn {
  position: relative;
  padding-left: $btn-padding-x / 2;
  padding-right: $btn-padding-x;
  padding-top: $btn-padding-y / 1.5;
  padding-bottom: $btn-padding-y / 1.5;
  width: calc(50% - 5px);
  text-align: center;
  border-radius: $btn-border-radius;
  font-size: $font-size-base * 0.8;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $btn-font-family;

  &:after {
    position: absolute;
    content: '';
    right: $btn-padding-x / 2;
    top: 50%;
    transform: rotate(45deg);
    z-index: 9;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    @include transition($btn-transition);

    @include media-breakpoint-up(md) {
      border-top: 3px solid $white;
      border-right: 3px solid $white;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      right: $btn-padding-x;
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 0.8;
    padding-top: $btn-padding-y;
    padding-bottom: $btn-padding-y;
    padding-left: $btn-padding-x;
    padding-right: $btn-padding-x;
  }
  @include media-breakpoint-up(md) {
    padding-right: $btn-padding-x * 2;
    font-size: $font-size-base * 0.9;
    width: auto;
  }
  @include media-breakpoint-up(lg) {
    font-size: $btn-font-size;
  }

  &.alt-btn--green {
    background-color: $corporateLightGreen;
    border: 3px solid $corporateLightGreen;
    color: #FFF;
    text-decoration: none;

    &:hover {
      background-color: #fff;
      color: $corporateLightGreen;
    }
  }

  &.alt-btn--blue {
    background-color: $corporateBlue;
    border: 3px solid $corporateBlue;
    color: #FFF;
    text-decoration: none;

    &:hover {
      background-color: #fff;
      color: $corporateBlue;
    }
  }

  &.alt-btn--red {
    background-color: $corporateRed;
    border: 3px solid $corporateRed;
    color: #FFF;
    text-decoration: none;

    &:hover {
      background-color: #fff;
      color: $corporateRed;
    }
  }

  + .alt-btn {
    margin-left: 10px;
  }

}

button.btn-plain {
  border: 0;
  background-color: transparent;
}

.btn {
  position: relative;
  padding-right: $btn-padding-x * 2 !important;

  &:after {
    position: absolute;
    content: '';
    right: $btn-padding-x;
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
    border-top: 3px solid $white;
    border-right: 3px solid $white;
    width: 10px;
    height: 10px;
    z-index: 9;
    @include transition($btn-transition);
  }

  &.arrow--white,
  &.action {
    &:after {
      border-top: 3px solid $white;
      border-right: 3px solid $white;
    }
  }

  &:hover {
    &:after {
      border-color: $corporateButtonColor;
    }
  }

  &.no--arrow {
    padding-right: $btn-padding-x !important;

    &:after {
      display: none;
    }
  }

  &.btn-outline-blue,
  &.btn-primary {
    color: $white;
    background-color: transparent;
    background-image: linear-gradient(to bottom, $corporateButtonGradientStart 0%, $corporateButtonGradientStop 100%);
    transition: none;
    border-color: $corporateButtonGradientStart transparent $corporateButtonGradientStop transparent;
    border-width: 3px;
  }

  &:hover {
    border: $outline-width solid $corporateGradientStop;
    background-image: none;
    background-color: transparent;
    color: $corporateGradientStop;
  }

  &.btn-gray {
    color: $white;
    background-color: $corporateGrayLogo !important;
    //background-image: linear-gradient(to bottom, $corporateGrayLogo 0%, $corporateGrayLogoStop 100%) !important;
    transition: none;
    border-color: $corporateGrayLogo;
    border-width: 3px;

    &:hover {
      color: $white;
      background-color: $corporateGrayLogoHover !important;
      border-color: $corporateGrayLogoHover !important;
      &:after {
        border-color: $white !important;
      }
    }
  }

  //&.btn-outline-blue:hover {
  //  color: $white;
  //  background-color: transparent;
  //  background-image: linear-gradient(to bottom, $corporateButtonGradientStart 0%, $corporateButtonGradientStop 100%);
  //  border: $outline-width solid transparent;
  //}

  //&.btn-outline {
  //  background-image: none;
  //  background-color: transparent;
  //  color: $black;
  //  border: $outline-width solid transparent;
  //
  //  &:hover {
  //    border: $outline-width solid $white;
  //    background-image: none;
  //    background-color: transparent;
  //    color: $corporateGradientStop;
  //  }
  //}

  &.btn-inversed {
    color: $corporateButtonColor;
    background-color: $white;
    font-weight: normal;
    border: $outline-width solid $white;

    &:after {
      border-top: 3px solid $corporateButtonColor;
      border-right: 3px solid $corporateButtonColor;
    }

    &:hover {
      border: $outline-width solid $white;
      background-image: none;
      background-color: transparent;
      color: $white;

      &:after {
        border-color: $white;
      }
    }
  }

  &.btn--red {
    color: $white !important;
    background-color: $corporateRed !important;
    border: $outline-width solid $corporateRed !important;
    background-image: none !important;

    &:after {
      border-top: 3px solid $white;
      border-right: 3px solid $white;
    }

    &:hover {
      border: $outline-width solid $corporateRed !important;
      background-image: none !important;
      background-color: $white !important;
      color: $corporateRed !important;

      &:after {
        border-color: $corporateRed;
      }
    }
  }


  @each $color, $value in $theme-colors {
    &.btn-outline-#{$color} {
      border: $outline-width solid $value;
      background-color: $white;
    }
  }

  .cms__content &,
  &.btn-half-fluid {
    text-align: left;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
      min-width: 75%;
    }
    @include media-breakpoint-up(lg) {
      min-width: 90%;
    }
    @include media-breakpoint-up(xl) {
      min-width: 75%;
    }
    @include media-breakpoint-up(xxl) {
      min-width: 50%;
    }
  }

  .col-xl-5 .cms__content &,
  .col-xl-5 &.btn-half-fluid {
    min-width: 70%;
  }

  .cms__content &.btn-fluid,
  &.btn-fluid {
    width: 100%;
    text-align: left;

    + .btn-fluid {
      margin-top: 22px;
    }
  }


}

input[type="submit"], .btn-toolbar .action {
  // from bootstrap buttons
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-align: left;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  position: relative;
  padding-right: $btn-padding-x * 2 !important;
  display: block;
  color: $white;
  background-image: linear-gradient(to bottom, $corporateButtonGradientStart 0%, $corporateButtonGradientStop 100%);
  transition: all ease 200ms;
  border-style: solid;
  border-color: $corporateButtonGradientStart transparent $corporateButtonGradientStop transparent;
  border-width: 3px;

  &:hover {
    background-image: linear-gradient(to bottom, $corporateButtonGradientStop 0%, $corporateButtonGradientStart 100%);
    border-color: $corporateButtonGradientStop;
    text-indent: 1px;
  }

  &:after {
    transition: all ease 200ms;
    position: absolute;
    content: '';
    right: $btn-padding-x;
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
    border-top: 3px solid $white;
    border-right: 3px solid $white;
    width: 10px;
    height: 10px;
    z-index: 9;
  }

  &:hover {
    &:after {
      right: $btn-padding-x - 5px;
    }
  }
}


.has-arrow-rounded .arrow-holder,
.btn--arrow-rounded {
  position: relative;
  padding-right: 40px;

  &:before {
    position: absolute;
    content: '';
    right: -2px;
    top: 50%;
    margin-top: -15px;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    z-index: 8;
    @include transition($btn-transition);
  }

  &:after {
    position: absolute;
    content: '';
    right: 10px;
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    width: 10px;
    height: 10px;
    z-index: 9;
    @include transition($btn-transition);
  }
}

.has-arrow-rounded:hover .arrow-holder:before,
.btn--arrow-rounded:hover:before {
  background-color: $black;
}

.has-arrow-rounded:hover .arrow-holder:after,
.btn--arrow-rounded:hover:after {
  border-color: $white;
}

.back-to-top {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 30px;
  width: 48px;
  height: 48px;
  padding: 0 !important;

  &:hover {
    background-color: darken($primary, 20%);
  }

  &:after {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-top: 4px solid #FFF;
    border-right: 4px solid #FFF;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -5px;
    transform: rotate(-45deg);
  }
}

.btn-inline-text {
  padding: 0 !important;
  background: transparent !important;
  border: 0 !important;
  font-family: $font-family-base !important;
  color: $corporateBlue !important;
  text-decoration: underline !important;
  font-size: 14px !important;
}
