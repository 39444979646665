@charset "UTF-8";
@font-face {
  font-family: "Avenir Black";
  src: local("Avenir Black"), local("Avenir-Black"), url("/_resources/themes/topsystems/webfonts/avenir-black.woff2") format("woff2"), url("/_resources/themes/topsystems/webfonts/avenir-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Light";
  src: local("Avenir Light"), local("Avenir-Light"), url("/_resources/themes/topsystems/webfonts/avenir-light.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), local("Avenir Regular"), local("Avenir-Regular"), local("Avenir-Roman"), url("/_resources/themes/topsystems/webfonts/avenir-roman.woff2") format("woff2"), url("/_resources/themes/topsystems/webfonts/avenir-roman.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir Black"), local("Avenir-Black"), url("/_resources/themes/topsystems/webfonts/avenir-black.woff2") format("woff2"), url("/_resources/themes/topsystems/webfonts/avenir-black.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #2f97da;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2f97da;
  --secondary: #FFF;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1660px;
  --breakpoint-xxxl: 1784px;
  --font-family-sans-serif: "Avenir", Arial, Helvetica, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(33, 37, 41, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.22;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.625rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2f97da;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1c6ca0;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.625rem;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 5.75rem;
}

h2, .h2 {
  font-size: 3.375rem;
}

h3, .h3 {
  font-size: 2rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.40625rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(33, 37, 41, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.40625rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 26px;
  padding-left: 26px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1660px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1600px;
  }
}
@media (min-width: 1784px) {
  .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1724px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -26px;
  margin-left: -26px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxxl,
.col-xxxl-auto, .col-xxxl-12, .col-xxxl-11, .col-xxxl-10, .col-xxxl-9, .col-xxxl-8, .col-xxxl-7, .col-xxxl-6, .col-xxxl-5, .col-xxxl-4, .col-xxxl-3, .col-xxxl-2, .col-xxxl-1, .col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 26px;
  padding-left: 26px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1660px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1784px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxxl-first {
    order: -1;
  }

  .order-xxxl-last {
    order: 13;
  }

  .order-xxxl-0 {
    order: 0;
  }

  .order-xxxl-1 {
    order: 1;
  }

  .order-xxxl-2 {
    order: 2;
  }

  .order-xxxl-3 {
    order: 3;
  }

  .order-xxxl-4 {
    order: 4;
  }

  .order-xxxl-5 {
    order: 5;
  }

  .order-xxxl-6 {
    order: 6;
  }

  .order-xxxl-7 {
    order: 7;
  }

  .order-xxxl-8 {
    order: 8;
  }

  .order-xxxl-9 {
    order: 9;
  }

  .order-xxxl-10 {
    order: 10;
  }

  .order-xxxl-11 {
    order: 11;
  }

  .order-xxxl-12 {
    order: 12;
  }

  .offset-xxxl-0 {
    margin-left: 0;
  }

  .offset-xxxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxxl-3 {
    margin-left: 25%;
  }

  .offset-xxxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxxl-6 {
    margin-left: 50%;
  }

  .offset-xxxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxxl-9 {
    margin-left: 75%;
  }

  .offset-xxxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(33, 37, 41, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(33, 37, 41, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5e2f5;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #93c9ec;
}

.table-hover .table-primary:hover {
  background-color: #afd7f1;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #afd7f1;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white;
}

.table-hover .table-secondary:hover {
  background-color: #f2f2f2;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f2f2f2;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(33, 37, 41, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(22, 24, 27, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(22, 24, 27, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1659.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1783.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.22em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.22;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bbb;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #9bcded;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.22;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.40625rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.984375rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.22;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.40625rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.984375rem;
  line-height: 1.22;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.22em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.305em + 0.1875rem) center;
  background-size: calc(0.61em + 0.375rem) calc(0.61em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.22em + 0.75rem);
  background-position: top calc(0.305em + 0.1875rem) right calc(0.305em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.61em + 0.375rem) calc(0.61em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.984375rem;
  line-height: 1.22;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.22em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.305em + 0.1875rem) center;
  background-size: calc(0.61em + 0.375rem) calc(0.61em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.22em + 0.75rem);
  background-position: top calc(0.305em + 0.1875rem) right calc(0.305em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.61em + 0.375rem) calc(0.61em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 9px 27px;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 26px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #2f97da;
  border-color: #2f97da;
}
.btn-primary:hover {
  color: #fff;
  background-color: #2282c1;
  border-color: #207bb6;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #2282c1;
  border-color: #207bb6;
  box-shadow: 0 0 0 0.2rem rgba(78, 167, 224, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2f97da;
  border-color: #2f97da;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #207bb6;
  border-color: #1e74ab;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 167, 224, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #2f97da;
  border-color: #2f97da;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #2f97da;
  border-color: #2f97da;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2f97da;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2f97da;
  border-color: #2f97da;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.5);
}

.btn-outline-secondary {
  color: #FFF;
  border-color: #FFF;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #FFF;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2f97da;
  text-decoration: none;
}
.btn-link:hover {
  color: #1c6ca0;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.40625rem;
  line-height: 1.5;
  border-radius: 26px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 27px;
  font-size: 0.984375rem;
  line-height: 1.5;
  border-radius: 26px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.125rem;
  color: #2f97da;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(33, 37, 41, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1660px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1784px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #2f97da;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2f97da;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.984375rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #2f97da;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 20.25px;
  padding-left: 20.25px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 20.25px;
  padding-left: 20.25px;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.22;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #bbb;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.40625rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3725rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.18625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #2f97da;
  background-color: #2f97da;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #9bcded;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #c7e3f5;
  border-color: #c7e3f5;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.18625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.18625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2f97da;
  background-color: #2f97da;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(47, 151, 218, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(47, 151, 218, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(47, 151, 218, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.18625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(47, 151, 218, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.22em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.22;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #bbb;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #9bcded;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.984375rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.40625rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.22em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.22em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #9bcded;
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.22em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.22;
  color: #495057;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.22em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.22;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2f97da;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #c7e3f5;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2f97da;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #c7e3f5;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #2f97da;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #c7e3f5;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2f97da;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl,
.navbar .container-xxxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3284375rem;
  padding-bottom: 0.3284375rem;
  margin-right: 1rem;
  font-size: 1.40625rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.40625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 26px;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl,
.navbar-expand-sm > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl,
.navbar-expand-sm > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl,
.navbar-expand-md > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl,
.navbar-expand-md > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl,
.navbar-expand-lg > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl,
.navbar-expand-lg > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl,
.navbar-expand-xl > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl,
.navbar-expand-xl > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1659.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl,
.navbar-expand-xxl > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1660px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl,
.navbar-expand-xxl > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1783.98px) {
  .navbar-expand-xxxl > .container,
.navbar-expand-xxxl > .container-fluid,
.navbar-expand-xxxl > .container-sm,
.navbar-expand-xxxl > .container-md,
.navbar-expand-xxxl > .container-lg,
.navbar-expand-xxxl > .container-xl,
.navbar-expand-xxxl > .container-xxl,
.navbar-expand-xxxl > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1784px) {
  .navbar-expand-xxxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-xxxl > .container,
.navbar-expand-xxxl > .container-fluid,
.navbar-expand-xxxl > .container-sm,
.navbar-expand-xxxl > .container-md,
.navbar-expand-xxxl > .container-lg,
.navbar-expand-xxxl > .container-xl,
.navbar-expand-xxxl > .container-xxl,
.navbar-expand-xxxl > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl,
.navbar-expand > .container-xxxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.875rem;
  padding-left: 0.875rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl,
.navbar-expand > .container-xxxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(33, 37, 41, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(33, 37, 41, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(33, 37, 41, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(33, 37, 41, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(33, 37, 41, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(33, 37, 41, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(33, 37, 41, 0.5);
  border-color: rgba(33, 37, 41, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(33, 37, 41, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(33, 37, 41, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(33, 37, 41, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(33, 37, 41, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(33, 37, 41, 0.03);
  border-bottom: 1px solid rgba(33, 37, 41, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(33, 37, 41, 0.03);
  border-top: 1px solid rgba(33, 37, 41, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 26px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -26px;
    margin-left: -26px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 26px;
    margin-bottom: 0;
    margin-left: 26px;
  }
}

.card-group > .card {
  margin-bottom: 26px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2f97da;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #1c6ca0;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2f97da;
  border-color: #2f97da;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.40625rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #2f97da;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #207bb6;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(47, 151, 218, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #FFF;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.1875rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #286085;
  background-color: #d5eaf8;
  border-color: #c5e2f5;
}
.alert-primary hr {
  border-top-color: #afd7f1;
}
.alert-primary .alert-link {
  color: #1c445e;
}

.alert-secondary {
  color: #949698;
  background-color: white;
  border-color: white;
}
.alert-secondary hr {
  border-top-color: #f2f2f2;
}
.alert-secondary .alert-link {
  color: #7a7d7f;
}

.alert-success {
  color: #256938;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #184324;
}

.alert-info {
  color: #1c6673;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #12424a;
}

.alert-warning {
  color: #947617;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #685310;
}

.alert-danger {
  color: #822d38;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #5c2028;
}

.alert-light {
  color: #919396;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #77797d;
}

.alert-dark {
  color: #2b3035;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #141719;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.84375rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2f97da;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(33, 37, 41, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2f97da;
  border-color: #2f97da;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1660px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1784px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #286085;
  background-color: #c5e2f5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #286085;
  background-color: #afd7f1;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #286085;
  border-color: #286085;
}

.list-group-item-secondary {
  color: #949698;
  background-color: white;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #949698;
  background-color: #f2f2f2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #949698;
  border-color: #949698;
}

.list-group-item-success {
  color: #256938;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #256938;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #256938;
  border-color: #256938;
}

.list-group-item-info {
  color: #1c6673;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1c6673;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1c6673;
  border-color: #1c6673;
}

.list-group-item-warning {
  color: #947617;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #947617;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #947617;
  border-color: #947617;
}

.list-group-item-danger {
  color: #822d38;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #822d38;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #822d38;
  border-color: #822d38;
}

.list-group-item-light {
  color: #919396;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #919396;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #919396;
  border-color: #919396;
}

.list-group-item-dark {
  color: #2b3035;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #2b3035;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2b3035;
  border-color: #2b3035;
}

.close {
  float: right;
  font-size: 1.6875rem;
  font-weight: 700;
  line-height: 1;
  color: #212529;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #212529;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(33, 37, 41, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(33, 37, 41, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #212529;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.22;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.22;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.984375rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #212529;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #212529;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #212529;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #212529;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #212529;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.22;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.984375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(33, 37, 41, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(33, 37, 41, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(33, 37, 41, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(33, 37, 41, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(33, 37, 41, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2f97da !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #207bb6 !important;
}

.bg-secondary {
  background-color: #FFF !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2f97da !important;
}

.border-secondary {
  border-color: #FFF !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1660px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1784px) {
  .d-xxxl-none {
    display: none !important;
  }

  .d-xxxl-inline {
    display: inline !important;
  }

  .d-xxxl-inline-block {
    display: inline-block !important;
  }

  .d-xxxl-block {
    display: block !important;
  }

  .d-xxxl-table {
    display: table !important;
  }

  .d-xxxl-table-row {
    display: table-row !important;
  }

  .d-xxxl-table-cell {
    display: table-cell !important;
  }

  .d-xxxl-flex {
    display: flex !important;
  }

  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1660px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1784px) {
  .flex-xxxl-row {
    flex-direction: row !important;
  }

  .flex-xxxl-column {
    flex-direction: column !important;
  }

  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxxl-center {
    justify-content: center !important;
  }

  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxxl-center {
    align-items: center !important;
  }

  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxxl-center {
    align-content: center !important;
  }

  .align-content-xxxl-between {
    align-content: space-between !important;
  }

  .align-content-xxxl-around {
    align-content: space-around !important;
  }

  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxxl-auto {
    align-self: auto !important;
  }

  .align-self-xxxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxxl-center {
    align-self: center !important;
  }

  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1660px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
@media (min-width: 1784px) {
  .float-xxxl-left {
    float: left !important;
  }

  .float-xxxl-right {
    float: right !important;
  }

  .float-xxxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(33, 37, 41, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(33, 37, 41, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1660px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1784px) {
  .m-xxxl-0 {
    margin: 0 !important;
  }

  .mt-xxxl-0,
.my-xxxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxxl-0,
.mx-xxxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxxl-0,
.my-xxxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxxl-0,
.mx-xxxl-0 {
    margin-left: 0 !important;
  }

  .m-xxxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxxl-1,
.my-xxxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxxl-1,
.mx-xxxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxxl-1,
.my-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxxl-1,
.mx-xxxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxxl-2,
.my-xxxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxxl-2,
.mx-xxxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxxl-2,
.my-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxxl-2,
.mx-xxxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxxl-3 {
    margin: 1rem !important;
  }

  .mt-xxxl-3,
.my-xxxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxxl-3,
.mx-xxxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxxl-3,
.my-xxxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxxl-3,
.mx-xxxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxxl-4,
.my-xxxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxxl-4,
.mx-xxxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxxl-4,
.my-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxxl-4,
.mx-xxxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxxl-5 {
    margin: 3rem !important;
  }

  .mt-xxxl-5,
.my-xxxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxxl-5,
.mx-xxxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxxl-5,
.my-xxxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxxl-5,
.mx-xxxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxxl-0 {
    padding: 0 !important;
  }

  .pt-xxxl-0,
.py-xxxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxxl-0,
.px-xxxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxxl-0,
.py-xxxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxxl-0,
.px-xxxl-0 {
    padding-left: 0 !important;
  }

  .p-xxxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxxl-1,
.py-xxxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxxl-1,
.px-xxxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxxl-1,
.py-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxxl-1,
.px-xxxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxxl-2,
.py-xxxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxxl-2,
.px-xxxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxxl-2,
.py-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxxl-2,
.px-xxxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxxl-3 {
    padding: 1rem !important;
  }

  .pt-xxxl-3,
.py-xxxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxxl-3,
.px-xxxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxxl-3,
.py-xxxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxxl-3,
.px-xxxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxxl-4,
.py-xxxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxxl-4,
.px-xxxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxxl-4,
.py-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxxl-4,
.px-xxxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxxl-5 {
    padding: 3rem !important;
  }

  .pt-xxxl-5,
.py-xxxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxxl-5,
.px-xxxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxxl-5,
.py-xxxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxxl-5,
.px-xxxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxxl-n1,
.my-xxxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxxl-n1,
.mx-xxxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxxl-n1,
.my-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxxl-n1,
.mx-xxxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxxl-n2,
.my-xxxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxxl-n2,
.mx-xxxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxxl-n2,
.my-xxxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxxl-n2,
.mx-xxxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxxl-n3,
.my-xxxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxxl-n3,
.mx-xxxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxxl-n3,
.my-xxxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxxl-n3,
.mx-xxxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxxl-n4,
.my-xxxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxxl-n4,
.mx-xxxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxxl-n4,
.my-xxxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxxl-n4,
.mx-xxxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxxl-n5,
.my-xxxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxxl-n5,
.mx-xxxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxxl-n5,
.my-xxxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxxl-n5,
.mx-xxxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxxl-auto {
    margin: auto !important;
  }

  .mt-xxxl-auto,
.my-xxxl-auto {
    margin-top: auto !important;
  }

  .mr-xxxl-auto,
.mx-xxxl-auto {
    margin-right: auto !important;
  }

  .mb-xxxl-auto,
.my-xxxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxxl-auto,
.mx-xxxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1660px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1784px) {
  .text-xxxl-left {
    text-align: left !important;
  }

  .text-xxxl-right {
    text-align: right !important;
  }

  .text-xxxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #2f97da !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1c6ca0 !important;
}

.text-secondary {
  color: #FFF !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(33, 37, 41, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #212529;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_resources/themes/topsystems/images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/_resources/themes/topsystems/webfonts/slick.eot");
  src: url("/_resources/themes/topsystems/webfonts/slick.eot?#iefix") format("embedded-opentype"), url("/_resources/themes/topsystems/webfonts/slick.woff") format("woff"), url("/_resources/themes/topsystems/webfonts/slick.ttf") format("truetype"), url("/_resources/themes/topsystems/webfonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

h1 {
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.h1--font {
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.growing-h1 {
  font-size: 1.725rem;
}
@media (min-width: 576px) {
  .growing-h1 {
    font-size: 2.3rem;
  }
}
@media (min-width: 768px) {
  .growing-h1 {
    font-size: 2.875rem;
  }
}
@media (min-width: 992px) {
  .growing-h1 {
    font-size: 3.45rem;
  }
}
@media (min-width: 1200px) {
  .growing-h1 {
    font-size: 4.3125rem;
  }
}
@media (min-width: 1660px) {
  .growing-h1 {
    font-size: 5.75rem;
    line-height: 1.228;
  }
}

.growing-h2,
.cms__content .growing-h2 {
  font-size: 1.6875rem;
}
@media (min-width: 992px) {
  .growing-h2,
.cms__content .growing-h2 {
    font-size: 2.025rem;
  }
}
@media (min-width: 1200px) {
  .growing-h2,
.cms__content .growing-h2 {
    font-size: 2.53125rem;
  }
}
@media (min-width: 1660px) {
  .growing-h2,
.cms__content .growing-h2 {
    font-size: 3.375rem;
  }
}

.growing-h3,
.cms__content h2:not(.growing-h1),
.cms__content h2:not(.growing-h3),
.cms__content h2:not(.growing-h4) {
  font-size: 1.5rem;
}
@media (min-width: 576px) {
  .growing-h3,
.cms__content h2:not(.growing-h1),
.cms__content h2:not(.growing-h3),
.cms__content h2:not(.growing-h4) {
    font-size: 1.7rem;
  }
}
@media (min-width: 768px) {
  .growing-h3,
.cms__content h2:not(.growing-h1),
.cms__content h2:not(.growing-h3),
.cms__content h2:not(.growing-h4) {
    font-size: 2rem;
  }
}

.growing-h4 {
  font-size: 1.125rem;
}
@media (min-width: 576px) {
  .growing-h4 {
    font-size: 1.275rem;
  }
}
@media (min-width: 768px) {
  .growing-h4 {
    font-size: 1.5rem;
  }
}

h3, .h3 {
  font-weight: 900;
}

h4, .h4 {
  font-weight: 900;
}

h5, .h5 {
  font-weight: 900;
}

h6, .h6 {
  font-weight: 900;
}

.font--base {
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .font--bigger {
    font-size: 24px;
  }
}

.font--increase {
  font-size: 130%;
}

.font--smaller {
  font-size: 13px;
}

.font-regular {
  font-family: "Avenir", Arial, Helvetica, sans-serif;
}

.font--black {
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 900;
}

div.ss-htmleditorfield-file.embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  width: 100%;
  overflow: hidden;
  max-width: 100% !important;
}
@media (min-width: 768px) {
  div.ss-htmleditorfield-file.embed {
    padding: 0;
    width: 640px;
    height: 360px;
  }
}
div.ss-htmleditorfield-file.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
div.ss-htmleditorfield-file.embed.center {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  div.ss-htmleditorfield-file.embed.video--basic {
    width: 720px;
  }
}
@media (min-width: 992px) {
  div.ss-htmleditorfield-file.embed.video--full {
    width: 100%;
    height: 100%;
    min-height: 612px;
  }
}
@media (min-width: 1660px) {
  div.ss-htmleditorfield-file.embed.video--full {
    min-height: 871px;
  }
}

.cms__content > div + p, .cms__content > div + div, .cms__content > div + h1, .cms__content > div + h2, .cms__content > div + h3, .cms__content > div + h4, .cms__content > div + h5, .cms__content > div + h6, .cms__content p + p, .cms__content p + div, .cms__content p + h1, .cms__content p + h2, .cms__content p + h3, .cms__content p + h4, .cms__content p + h5, .cms__content p + h6 {
  margin-top: 1rem;
}
.cms__content h2, .cms__content .h2 {
  word-wrap: break-word;
}
.cms__content h3, .cms__content .h3 {
  font-size: 1.125rem;
}
@media (min-width: 992px) {
  .cms__content h3, .cms__content .h3 {
    font-size: 1.5rem;
  }
}
.cms__content h4, .cms__content .h4, .cms__content h5, .cms__content .h5 {
  font-size: 1.125rem;
}
.cms__content h6, .cms__content .h6 {
  font-size: 1rem;
}
.cms__content + .btn {
  margin-top: 20px;
}

.bg-blue a:not(.btn) {
  color: #fff;
  text-decoration: underline;
}

.text-blue {
  color: #2f97da;
}

@media (min-width: 992px) {
  ul.two-columns {
    columns: 2;
  }
  ul.two-columns li {
    break-inside: avoid;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.simple__link {
  text-align: center;
  text-decoration: underline;
  color: #212529;
}

.page--prev {
  display: inline-block;
  margin-right: 20px;
}

.page--next {
  display: inline-block;
  margin-left: 20px;
}

.simple__link--center {
  display: block;
  text-align: center;
  text-decoration: underline;
  color: #212529;
}

.sticky__title {
  position: sticky;
  top: 0;
}
@media (min-width: 1660px) {
  .sticky__title {
    margin-top: -12px;
    padding-right: 60px;
  }
}
@media (min-width: 992px) {
  body:not(.nav-hidden).scrolled-down.has-scrolled .sticky__title {
    top: 140px;
  }
}
@media (min-width: 1200px) {
  body:not(.nav-hidden).scrolled-down.has-scrolled .sticky__title {
    top: 90px;
  }
}

.visually-hidden {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  left: -200vw;
}

/*
 * Squeeze
 */
.hamburger {
  position: absolute;
  z-index: 10014;
  box-shadow: none;
  padding: 14px 15px;
  display: inline-block;
  cursor: pointer;
  height: 46px;
  transition-property: opacity, filter, top;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: white;
  border: 0;
  margin: 0;
  overflow: visible;
  right: 0;
  top: 0;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger.is-active:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 32px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 32px;
  height: 2px;
  background-color: #000;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.mobile-menu-open, html.mobile-menu-open body {
  overflow: hidden;
}
html.mobile-menu-open #header {
  position: fixed !important;
  top: 0;
}

body {
  background-color: #2083CF;
}

.mb-60 {
  margin-bottom: 30px !important;
}
@media (min-width: 992px) {
  .mb-60 {
    margin-bottom: 60px !important;
  }
}

.mt-60 {
  margin-top: 30px !important;
}
@media (min-width: 992px) {
  .mt-60 {
    margin-top: 60px !important;
  }
}

.pt-60 {
  padding-top: 30px !important;
}
@media (min-width: 992px) {
  .pt-60 {
    padding-top: 60px !important;
  }
}

.pb-60 {
  padding-bottom: 30px !important;
}
@media (min-width: 992px) {
  .pb-60 {
    padding-bottom: 60px !important;
  }
}

.mb-120 {
  margin-bottom: 60px !important;
}
@media (min-width: 992px) {
  .mb-120 {
    margin-bottom: 120px !important;
  }
}

.mt-120 {
  margin-top: 60px !important;
}
@media (min-width: 992px) {
  .mt-120 {
    margin-top: 120px !important;
  }
}

.pt-120 {
  padding-top: 60px !important;
}
@media (min-width: 992px) {
  .pt-120 {
    padding-top: 120px !important;
  }
}

.pb-120 {
  padding-bottom: 60px !important;
}
@media (min-width: 992px) {
  .pb-120 {
    padding-bottom: 120px !important;
  }
}

.container.container--narrow-gutter .row {
  margin-right: -10px;
  margin-left: -10px;
}
.container.container--narrow-gutter .row .col-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-sm-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-md-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-lg-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xl-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxl-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.container.container--narrow-gutter .row .col-xxxl-12 {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .col-lg-40 {
    flex: 0 0 calc(50% - 75px);
    max-width: calc(50% - 75px);
  }

  .col-lg-60 {
    flex: 0 0 calc(50% + 75px);
    max-width: calc(50% + 75px);
  }

  .col-lg-30 {
    flex: 0 0 calc(50% - 100px);
    max-width: calc(50% - 100px);
  }

  .col-lg-70 {
    flex: 0 0 calc(50% + 100px);
    max-width: calc(50% + 100px);
  }

  .col-lg-50 {
    flex: 0 0 calc(50% - 26px);
    max-width: calc(50% - 26px);
  }
}
.text-orange {
  color: #fa5000;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl {
  max-width: 1600px;
}

header.header,
#mainContainer {
  background-color: #FFF;
}

.HomePage #mainContainer, .ContactPage #mainContainer {
  padding-bottom: 0;
}

.captionImage {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.with--ico img {
  display: inline-block;
  width: auto;
  height: 34px;
  margin-right: 10px;
  vertical-align: middle;
  object-fit: contain;
}

.bg-gray {
  background-color: #eceded;
}

.page__header {
  background: no-repeat center center #0492d4;
  background-size: cover;
  background-blend-mode: multiply;
  background-image: url("/_resources/themes/topsystems/images/header-mobile.jpg");
  color: #fff;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (min-width: 768px) {
  .page__header {
    background-image: url("/_resources/themes/topsystems/images/header.jpg");
    height: 500px;
  }
}
body.Sector .page__header, body.SolutionOverview .page__header, body.Showcase .page__header, body.SectorOverview .page__header {
  background-image: url("/_resources/themes/topsystems/images/header2-mobile.jpg");
}
@media (min-width: 768px) {
  body.Sector .page__header, body.SolutionOverview .page__header, body.Showcase .page__header, body.SectorOverview .page__header {
    background-image: url("/_resources/themes/topsystems/images/header2.jpg");
    height: 500px;
  }
}
@media (min-width: 992px) {
  body.Sector .page__header, body.SolutionOverview .page__header, body.Showcase .page__header, body.SectorOverview .page__header {
    height: 700px;
  }
}

.absolute-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.blocks__even {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .blocks__even {
    flex-direction: row;
  }
}

.w-md-1\/5 {
  flex-basis: 20%;
}

.block--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media (min-width: 576px) {
  .container-has-bottom-line .container,
.has-bottom-line {
    position: relative;
    border-bottom: 1px solid #212529;
    padding-bottom: 50px;
  }
  .container-has-bottom-line .container:before, .container-has-bottom-line .container:after,
.has-bottom-line:before,
.has-bottom-line:after {
    position: absolute;
    content: "";
    bottom: -7px;
    width: 1px;
    height: 15px;
    z-index: 9;
    border-left: 1px solid #212529;
  }
  .container-has-bottom-line .container:before,
.has-bottom-line:before {
    left: -1px;
  }
  .container-has-bottom-line .container:after,
.has-bottom-line:after {
    right: -1px;
  }
}

.container-has-bottom-line + .outer-row:not(.gray):not(.blue) {
  padding-top: 0;
}

.has-top-line {
  position: relative;
  border-top: 1px solid #212529;
  padding-top: 50px;
}
.has-top-line:before, .has-top-line:after {
  position: absolute;
  content: "";
  top: -7px;
  width: 1px;
  height: 15px;
  z-index: 9;
  border-left: 1px solid #212529;
}
.has-top-line:before {
  left: -1px;
}
.has-top-line:after {
  right: -1px;
}

div.clamp + [data-readmore-toggle], div.clamp[data-readmore] {
  display: block;
  width: 100%;
}

div.clamp[data-readmore] {
  transition: height 75ms;
  overflow: hidden;
}

.centered-block {
  text-align: center;
}
.centered-block p:last-child {
  margin-bottom: 0;
}

.page__centered-block {
  text-align: center;
}
.page__centered-block p:last-child {
  margin-bottom: 0;
}

.page__centered-block--medium {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .page__centered-block--large {
    margin-left: 110px;
    margin-right: 110px;
  }
}

.page__centered-block--content {
  position: relative;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .page__centered-block--content {
    min-height: 150px;
    padding: 0 40px;
  }
}
.page__centered-block--content .page__title {
  line-height: 40px;
}

.page__title {
  margin-bottom: 24px;
}

.page__title--blue {
  color: #1C6AC3;
}

.clamp-holder.opening .page__centered-block--content {
  height: auto;
}
.clamp-holder.opened .page__centered-block--content {
  height: auto;
}
.clamp-holder a[data-readmore-toggle] {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
}

.table__holder {
  margin-top: 20px;
  margin-bottom: 20px;
}
.table__holder.no-image {
  margin-top: 0;
}
.table__holder table td + td,
.table__holder table td + th,
.table__holder table th + td {
  padding-left: 20px;
}
.table__holder a {
  color: #212529;
  text-decoration: underline;
}

.table--striped td + td,
.table--striped td + th,
.table--striped th + td {
  padding-left: 20px;
}
.table--striped td, .table--striped th {
  padding: 4px 7px;
}
.table--striped tr:nth-child(odd) {
  background-color: #cbe9f5;
}

.image--aside {
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  .image--aside {
    width: 50%;
    float: right;
    margin: 0 0 20px 20px;
  }
}
@media (min-width: 992px) {
  .image--aside {
    width: 40%;
    margin: 0 0 40px 40px;
  }
}

.alt-btn {
  position: relative;
  padding-left: 13.5px;
  padding-right: 27px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: calc(50% - 5px);
  text-align: center;
  border-radius: 26px;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
}
.alt-btn:after {
  position: absolute;
  content: "";
  right: 13.5px;
  top: 50%;
  transform: rotate(45deg);
  z-index: 9;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .alt-btn:after {
    transition: none;
  }
}
@media (min-width: 768px) {
  .alt-btn:after {
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    right: 27px;
  }
}
@media (min-width: 576px) {
  .alt-btn {
    font-size: 0.9rem;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 27px;
    padding-right: 27px;
  }
}
@media (min-width: 768px) {
  .alt-btn {
    padding-right: 54px;
    font-size: 1.0125rem;
    width: auto;
  }
}
@media (min-width: 992px) {
  .alt-btn {
    font-size: 1.125rem;
  }
}
.alt-btn.alt-btn--green {
  background-color: #1eb828;
  border: 3px solid #1eb828;
  color: #FFF;
  text-decoration: none;
}
.alt-btn.alt-btn--green:hover {
  background-color: #fff;
  color: #1eb828;
}
.alt-btn.alt-btn--blue {
  background-color: #2f97da;
  border: 3px solid #2f97da;
  color: #FFF;
  text-decoration: none;
}
.alt-btn.alt-btn--blue:hover {
  background-color: #fff;
  color: #2f97da;
}
.alt-btn.alt-btn--red {
  background-color: #fa5000;
  border: 3px solid #fa5000;
  color: #FFF;
  text-decoration: none;
}
.alt-btn.alt-btn--red:hover {
  background-color: #fff;
  color: #fa5000;
}
.alt-btn + .alt-btn {
  margin-left: 10px;
}

button.btn-plain {
  border: 0;
  background-color: transparent;
}

.btn {
  position: relative;
  padding-right: 54px !important;
}
.btn:after {
  position: absolute;
  content: "";
  right: 27px;
  top: 50%;
  margin-top: -5px;
  transform: rotate(45deg);
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  width: 10px;
  height: 10px;
  z-index: 9;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn:after {
    transition: none;
  }
}
.btn.arrow--white:after, .btn.action:after {
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
}
.btn:hover:after {
  border-color: #218AD2;
}
.btn.no--arrow {
  padding-right: 27px !important;
}
.btn.no--arrow:after {
  display: none;
}
.btn.btn-outline-blue, .btn.btn-primary {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #2083CF 0%, #1C6AC3 100%);
  transition: none;
  border-color: #2083CF transparent #1C6AC3 transparent;
  border-width: 3px;
}
.btn:hover {
  border: 3px solid #046AC3;
  background-image: none;
  background-color: transparent;
  color: #046AC3;
}
.btn.btn-gray {
  color: #fff;
  background-color: #a1a5a7 !important;
  transition: none;
  border-color: #a1a5a7;
  border-width: 3px;
}
.btn.btn-gray:hover {
  color: #fff;
  background-color: #2e2e31 !important;
  border-color: #2e2e31 !important;
}
.btn.btn-gray:hover:after {
  border-color: #fff !important;
}
.btn.btn-inversed {
  color: #218AD2;
  background-color: #fff;
  font-weight: normal;
  border: 3px solid #fff;
}
.btn.btn-inversed:after {
  border-top: 3px solid #218AD2;
  border-right: 3px solid #218AD2;
}
.btn.btn-inversed:hover {
  border: 3px solid #fff;
  background-image: none;
  background-color: transparent;
  color: #fff;
}
.btn.btn-inversed:hover:after {
  border-color: #fff;
}
.btn.btn--red {
  color: #fff !important;
  background-color: #fa5000 !important;
  border: 3px solid #fa5000 !important;
  background-image: none !important;
}
.btn.btn--red:after {
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
}
.btn.btn--red:hover {
  border: 3px solid #fa5000 !important;
  background-image: none !important;
  background-color: #fff !important;
  color: #fa5000 !important;
}
.btn.btn--red:hover:after {
  border-color: #fa5000;
}
.btn.btn-outline-primary {
  border: 3px solid #2f97da;
  background-color: #fff;
}
.btn.btn-outline-secondary {
  border: 3px solid #FFF;
  background-color: #fff;
}
.btn.btn-outline-success {
  border: 3px solid #28a745;
  background-color: #fff;
}
.btn.btn-outline-info {
  border: 3px solid #17a2b8;
  background-color: #fff;
}
.btn.btn-outline-warning {
  border: 3px solid #ffc107;
  background-color: #fff;
}
.btn.btn-outline-danger {
  border: 3px solid #dc3545;
  background-color: #fff;
}
.btn.btn-outline-light {
  border: 3px solid #f8f9fa;
  background-color: #fff;
}
.btn.btn-outline-dark {
  border: 3px solid #343a40;
  background-color: #fff;
}
.cms__content .btn, .btn.btn-half-fluid {
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .cms__content .btn, .btn.btn-half-fluid {
    width: auto;
    min-width: 75%;
  }
}
@media (min-width: 992px) {
  .cms__content .btn, .btn.btn-half-fluid {
    min-width: 90%;
  }
}
@media (min-width: 1200px) {
  .cms__content .btn, .btn.btn-half-fluid {
    min-width: 75%;
  }
}
@media (min-width: 1660px) {
  .cms__content .btn, .btn.btn-half-fluid {
    min-width: 50%;
  }
}
.col-xl-5 .cms__content .btn, .col-xl-5 .btn.btn-half-fluid {
  min-width: 70%;
}
.cms__content .btn.btn-fluid, .btn.btn-fluid {
  width: 100%;
  text-align: left;
}
.cms__content .btn.btn-fluid + .btn-fluid, .btn.btn-fluid + .btn-fluid {
  margin-top: 22px;
}

input[type=submit], .btn-toolbar .action {
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-align: left;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  padding: 9px 27px;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 26px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  padding-right: 54px !important;
  display: block;
  color: #fff;
  background-image: linear-gradient(to bottom, #2083CF 0%, #1C6AC3 100%);
  transition: all ease 200ms;
  border-style: solid;
  border-color: #2083CF transparent #1C6AC3 transparent;
  border-width: 3px;
}
@media (prefers-reduced-motion: reduce) {
  input[type=submit], .btn-toolbar .action {
    transition: none;
  }
}
input[type=submit]:hover, .btn-toolbar .action:hover {
  background-image: linear-gradient(to bottom, #1C6AC3 0%, #2083CF 100%);
  border-color: #1C6AC3;
  text-indent: 1px;
}
input[type=submit]:after, .btn-toolbar .action:after {
  transition: all ease 200ms;
  position: absolute;
  content: "";
  right: 27px;
  top: 50%;
  margin-top: -5px;
  transform: rotate(45deg);
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  width: 10px;
  height: 10px;
  z-index: 9;
}
input[type=submit]:hover:after, .btn-toolbar .action:hover:after {
  right: 22px;
}

.has-arrow-rounded .arrow-holder,
.btn--arrow-rounded {
  position: relative;
  padding-right: 40px;
}
.has-arrow-rounded .arrow-holder:before,
.btn--arrow-rounded:before {
  position: absolute;
  content: "";
  right: -2px;
  top: 50%;
  margin-top: -15px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  z-index: 8;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .has-arrow-rounded .arrow-holder:before,
.btn--arrow-rounded:before {
    transition: none;
  }
}
.has-arrow-rounded .arrow-holder:after,
.btn--arrow-rounded:after {
  position: absolute;
  content: "";
  right: 10px;
  top: 50%;
  margin-top: -5px;
  transform: rotate(45deg);
  border-top: 2px solid #2f97da;
  border-right: 2px solid #2f97da;
  width: 10px;
  height: 10px;
  z-index: 9;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .has-arrow-rounded .arrow-holder:after,
.btn--arrow-rounded:after {
    transition: none;
  }
}

.has-arrow-rounded:hover .arrow-holder:before,
.btn--arrow-rounded:hover:before {
  background-color: #212529;
}

.has-arrow-rounded:hover .arrow-holder:after,
.btn--arrow-rounded:hover:after {
  border-color: #fff;
}

.back-to-top {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 30px;
  width: 48px;
  height: 48px;
  padding: 0 !important;
}
.back-to-top:hover {
  background-color: #195e8a;
}
.back-to-top:after {
  display: none;
}
.back-to-top:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-top: 4px solid #FFF;
  border-right: 4px solid #FFF;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -5px;
  transform: rotate(-45deg);
}

.btn-inline-text {
  padding: 0 !important;
  background: transparent !important;
  border: 0 !important;
  font-family: "Avenir", Arial, Helvetica, sans-serif !important;
  color: #2f97da !important;
  text-decoration: underline !important;
  font-size: 14px !important;
}

.element img {
  max-width: 100%;
}

.dnadesign__elementallist__model__elementlist {
  padding: 20px 0;
}

.list-element__container {
  display: flex;
}
.list-element__container .element {
  flex-basis: 50%;
}
.list-element__container .element + .element {
  margin-left: 40px;
}

.inner-row,
.outer-row {
  padding: 20px 0;
}
@media (min-width: 768px) {
  .inner-row,
.outer-row {
    padding: 40px 0;
  }
}
@media (min-width: 576px) and (min-height: 900px) {
  .inner-row,
.outer-row {
    padding: 120px 0;
  }
}
.inner-row.blue,
.outer-row.blue {
  color: #fff;
  background-color: #2f97da;
}
.inner-row.gray, .inner-row.grey,
.outer-row.gray,
.outer-row.grey {
  background-color: #EDEEEE;
}
.inner-row.gray-stop-late, .inner-row.grey-stop-late,
.outer-row.gray-stop-late,
.outer-row.grey-stop-late {
  background-color: #EDEEEE;
}
@media (min-width: 768px) {
  .inner-row.gray-stop-late, .inner-row.grey-stop-late,
.outer-row.gray-stop-late,
.outer-row.grey-stop-late {
    background-color: transparent;
    background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 80%, transparent 80%, transparent 100%);
  }
}
.inner-row.gray-stop-early, .inner-row.grey-stop-early,
.outer-row.gray-stop-early,
.outer-row.grey-stop-early {
  background-color: #EDEEEE;
}
@media (min-width: 768px) {
  .inner-row.gray-stop-early, .inner-row.grey-stop-early,
.outer-row.gray-stop-early,
.outer-row.grey-stop-early {
    background-color: transparent;
    background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 65%, transparent 65%, transparent 100%);
  }
}
@media (min-width: 768px) {
  .inner-row.gray-stop-half, .inner-row.grey-stop-half,
.outer-row.gray-stop-half,
.outer-row.grey-stop-half {
    background-color: transparent;
    background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 50%, transparent 50%, transparent 100%);
  }
}
.inner-row.gray-stop-fixed, .inner-row.grey-stop-fixed,
.outer-row.gray-stop-fixed,
.outer-row.grey-stop-fixed {
  background-color: #EDEEEE;
}
@media (min-width: 768px) {
  .inner-row.gray-stop-fixed, .inner-row.grey-stop-fixed,
.outer-row.gray-stop-fixed,
.outer-row.grey-stop-fixed {
    background-color: transparent;
    background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 150px, transparent 150px, transparent 100%);
  }
}
.inner-row.padding-if-color-difference,
.outer-row.padding-if-color-difference {
  padding-top: 0.25rem;
}
.outer-row.bg-blue + .inner-row.padding-if-color-difference, .outer-row.bg-blue-slider + .inner-row.padding-if-color-difference, .outer-row.bg-blue-gray + .inner-row.padding-if-color-difference,
.outer-row.bg-blue + .outer-row.padding-if-color-difference,
.outer-row.bg-blue-slider + .outer-row.padding-if-color-difference,
.outer-row.bg-blue-gray + .outer-row.padding-if-color-difference {
  padding: 20px 0;
}
@media (min-width: 768px) {
  .outer-row.bg-blue + .inner-row.padding-if-color-difference, .outer-row.bg-blue-slider + .inner-row.padding-if-color-difference, .outer-row.bg-blue-gray + .inner-row.padding-if-color-difference,
.outer-row.bg-blue + .outer-row.padding-if-color-difference,
.outer-row.bg-blue-slider + .outer-row.padding-if-color-difference,
.outer-row.bg-blue-gray + .outer-row.padding-if-color-difference {
    padding: 40px 0;
  }
}
@media (min-height: 900px) {
  .outer-row.bg-blue + .inner-row.padding-if-color-difference, .outer-row.bg-blue-slider + .inner-row.padding-if-color-difference, .outer-row.bg-blue-gray + .inner-row.padding-if-color-difference,
.outer-row.bg-blue + .outer-row.padding-if-color-difference,
.outer-row.bg-blue-slider + .outer-row.padding-if-color-difference,
.outer-row.bg-blue-gray + .outer-row.padding-if-color-difference {
    padding: 60px 0;
  }
}

div.bg-white + .color-difference {
  background-color: #EDEEEE !important;
}
div.bg-white + .color-difference + .color-difference {
  background-color: #fff !important;
}
div.bg-white + .color-difference + .color-difference + .color-difference {
  background-color: #EDEEEE !important;
}
div.bg-white + .color-difference + .color-difference + .color-difference + .color-difference {
  background-color: #fff !important;
}
div.bg-white + .color-difference + .color-difference + .color-difference + .color-difference + .color-difference {
  background-color: #EDEEEE !important;
}
div.bg-white + .color-difference + .color-difference + .color-difference + .color-difference + .color-difference + .color-difference {
  background-color: #fff !important;
}

.has-popout-gray {
  background-color: #EDEEEE;
}
@media (min-width: 768px) {
  .has-popout-gray {
    background-color: transparent;
    background-image: linear-gradient(to right, transparent 0%, transparent 50%, #edeeee 50%, #edeeee 100%);
  }
}

@media (min-width: 768px) {
  .col--popout {
    margin-right: -50px;
  }
}
@media (min-width: 992px) {
  .col--popout {
    margin-right: -100px;
  }
}
@media (min-width: 1200px) {
  .col--popout {
    margin-right: -150px;
  }
}
@media (min-width: 1660px) {
  .col--popout {
    margin-right: -420px;
  }
}

.popout.gray-right {
  background-color: #EDEEEE;
}
@media (min-width: 768px) {
  .popout.gray-right {
    background-color: transparent;
    background-image: linear-gradient(to right, transparent 0%, transparent 100px, #edeeee 100px, #edeeee 100%);
  }
}

.bg-blue {
  color: #fff;
  background-color: #2f97da;
}

.bg-blue-slider {
  background-color: #cbe9f5;
}

.bg-gray, .bg-grey {
  background-color: #EDEEEE;
}

.top__block ul {
  padding-left: 19px;
  line-height: 1.61em;
}
.top__block em {
  font-style: normal;
  text-decoration: underline;
}

.shadow__block {
  box-shadow: 0 3px 99px rgba(0, 0, 0, 0.15);
}

.blue-header__block {
  box-shadow: 0 3px 99px rgba(0, 0, 0, 0.15);
}

.white__block {
  background-color: #fff;
  box-shadow: 0 3px 99px rgba(0, 0, 0, 0.15);
}
.slick-list .white__block {
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15);
}
.white__block, .white__block a:not(.btn) {
  color: #212529;
}

.h4--blue h4 {
  color: #2f97da;
}

.block--half-icon .half-icon__holder {
  position: relative;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .block--half-icon .half-icon__holder {
    margin-bottom: 0;
  }
}
.block--half-icon .half-icon__holder .half-icon {
  height: 100px;
  width: 100px;
  background: radial-gradient(at 120% 50%, #0492D4 0%, #046AC3 100%);
  border-radius: 50px;
  margin-top: -50px;
  display: flex;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
@media (min-width: 768px) {
  .block--half-icon .half-icon__holder .half-icon.icon--right {
    left: 100%;
    top: 50%;
  }
}
.block--half-icon .half-icon__holder .half-icon img {
  width: 70px;
  height: 60px;
  object-fit: contain;
}
.block--half-icon .block--padding.half-icon__content {
  padding-top: 30px;
}
@media (min-width: 768px) {
  .block--half-icon .block--padding.half-icon__content {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  .block--half-icon .block--padding.half-icon__content {
    padding-top: 90px;
  }
}

.border__block {
  padding: 3px;
}
.border__block .border__block--header {
  color: #fff;
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: bold;
}
.border__block .border__block--inner {
  background-color: #fff;
  padding: 20px 40px;
}

.image__row img {
  max-width: calc(100% + 52px);
  margin-left: -26px;
  margin-right: -26px;
}

.block--padding {
  padding: 20px;
}
@media (min-width: 992px) {
  .block--padding {
    padding: 30px;
  }
}
@media (min-width: 1200px) {
  .block--padding {
    padding: 60px;
  }
}
.block--padding.block--narrow, .block--narrow .block--padding {
  padding: 20px;
}
@media (min-width: 992px) {
  .block--padding.block--narrow, .block--narrow .block--padding {
    padding: 40px;
  }
}
@media (min-width: 1660px) {
  .block--padding.block--wide, .block--wide .block--padding {
    padding: 70px 100px;
  }
}

.block--gray {
  background-color: #EDEEEE;
}
.block--gray a:not(.btn) {
  color: #212529;
}

.highlighted__block-holder {
  position: relative;
}
.highlighted__block-holder.highlighted__block--blue {
  color: #fff;
  background-color: #2f97da;
}

.contact__block--holder .image__pull--left-right-side {
  min-height: 536px;
}
@media (min-width: 992px) {
  .contact__block--holder .block--padding {
    padding-left: 66px;
  }
}
@media (max-width: 767.98px) {
  .contact__block--holder .cover--image img {
    height: 200px;
  }
}

.image__pull--left {
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 991.98px) {
  .image__pull--left {
    right: 0;
    height: 400px;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
  .contact__block--holder .image__pull--left {
    height: 200px;
  }
}
.image__pull--left img {
  width: 100%;
}
@media (min-width: 992px) {
  .image__pull--left {
    left: -26px;
    bottom: 0;
  }
}

.image__pull--left-right-side {
  margin-top: 400px;
}
.contact__block--holder .image__pull--left-right-side {
  margin-top: 200px;
}
@media (min-width: 992px) {
  .image__pull--left-right-side {
    margin-top: 0 !important;
    margin-left: calc(50% - 52px);
  }
}
@media (min-width: 1660px) {
  .image__pull--left-right-side {
    margin-right: -200px;
  }
}

.training-explanation .image__pull--right-left-side {
  min-height: 430px;
}

.image__pull--right {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 991.98px) {
  .image__pull--right {
    left: 0;
    height: 400px;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.image__pull--right img {
  width: 100%;
}
@media (min-width: 992px) {
  .image__pull--right {
    bottom: 0;
  }
  .image__pull--right img {
    margin-left: 26px;
  }
}

.image__pull--right-left-side {
  margin-top: 400px;
}
@media (min-width: 992px) {
  .image__pull--right-left-side {
    margin-top: 0;
    margin-right: calc(50% - 52px);
  }
}

body:not(.nav-hidden) {
  transition: padding-top 200ms ease;
  padding-top: 110px;
}
@media (min-width: 1200px) {
  body:not(.nav-hidden) {
    padding-top: 150px;
  }
  body:not(.nav-hidden).scrolled-down.has-scrolled {
    padding-top: 84px;
  }
}

body:not(.nav-hidden) header.header {
  position: fixed;
  z-index: 90;
  left: 0;
  right: 0;
  top: 0;
  height: 110px;
}
@media (min-width: 1200px) {
  body:not(.nav-hidden) header.header {
    transition: height 200ms ease;
    height: 150px;
  }
}
@media (min-width: 1200px) {
  body:not(.nav-hidden).scrolled-down.has-scrolled header.header {
    height: 84px;
  }
}

@media (max-width: 1199.98px) {
  .navbar-collapse {
    background-color: #fff;
    position: absolute;
    padding: 20px;
    top: 70px;
    right: 0;
    left: 0;
    z-index: 250;
  }
}
@media (min-width: 768px) {
  .navbar-collapse li.nav-item {
    text-align: right;
  }
}

.header .navbar-brand {
  width: 200px;
  height: 90px;
  padding: 0;
  transition: ease 200ms width;
}
@media (min-width: 576px) {
  .header .navbar-brand {
    width: 250px;
  }
}
@media (min-width: 1200px) {
  .header .navbar-brand {
    width: 215px;
  }
}
@media (min-width: 1660px) {
  body:not(.scrolled-down) .header .navbar-brand {
    width: 416px;
  }
}
.navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    margin-top: 0;
  }

  .dropdown-toggle {
    margin-bottom: 2px;
  }

  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle {
    margin-bottom: 0;
  }
}
.navbar .nav-item {
  display: inline-flex;
  align-items: center;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 900;
}
.navbar .dropdown-item {
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 900;
}

#mainMenu .nav-link {
  outline: none;
}
@media (max-width: 991.98px) {
  #mainMenu .dropdown-menu {
    border: 0;
  }
}
@media (min-width: 992px) {
  #mainMenu .nav-item:last-child .btn {
    margin-left: 10px;
  }
}

.top__line {
  transition: margin-top 200ms ease;
  overflow: hidden;
  margin-top: 10px;
}
@media (min-width: 1200px) {
  .top__line {
    margin-bottom: 15px;
  }
}
@media (min-width: 1660px) {
  .top__line {
    margin-bottom: 22px;
  }
}
.top__line .container {
  position: relative;
}
body.scrolled-down.has-scrolled .top__line {
  margin-top: -30px;
  opacity: 0 !important;
}

#topMenu a {
  color: #909090;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
#topMenu .nav-item {
  font-weight: normal;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
}
#topMenu .nav-item .nav-link {
  display: flex;
  align-items: center;
}
#topMenu .nav-item .nav-link img {
  height: 22px;
  margin-right: 9px;
}
#topMenu .language__selector {
  border-left: 1px solid #909090;
  font-size: 9px;
  line-height: 1.5;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
#topMenu .language__selector .dropdown-menu {
  padding: 0.8rem 1rem;
  left: -0.3rem;
  min-width: 0;
}
#topMenu .language__selector button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  border: 0;
  padding: 0.2rem 0.8rem;
  font-size: 9px;
  height: 24px;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
#topMenu .language__selector button:after {
  display: none;
}

.main__line {
  padding-top: 0;
}

.mobile-buttons {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateY(50%);
  z-index: 8;
}
@media (max-width: 991.98px) {
  .mobile-buttons > .container {
    padding: 0 1rem;
  }
}

@media (max-width: 575.98px) {
  .navbar-toggler {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* purgecss start ignore */
/* GENERIC FORMS
----------------------------------------------- */
div[id$=Url_Holder],
div[id$=Comment_Holder] {
  position: absolute;
  left: -6000px;
  top: -6000px;
}

form {
  /* Radio and Checkbox */
  /* Messages */
  /* ACTIONS */
  /* AREA SPECIFIC */
  /* LOGIN and FORGOT PASSWORD */
}
form input[type=checkbox] {
  width: 20px;
  height: 20px;
  border-color: #212529;
}
form input[type=checkbox]:checked {
  border-color: #2f97da;
}
@media (min-width: 1200px) {
  form .two--columns div.middleColumn ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  form .two--columns div.middleColumn ul li {
    white-space: nowrap;
  }
}
form div.field + .field:not(.col-12) {
  margin-top: 30px;
}
@media (min-width: 768px) {
  form.form--horizontal div.field + .field {
    margin-top: 0;
  }
}
form .field.nocaptcha {
  margin-top: 30px;
  margin-bottom: 30px;
}
form .full {
  width: 100%;
}
form fieldset + .btn-toolbar, form .fieldset + .btn-toolbar {
  margin-top: 10px;
}
form .form--alert {
  color: #fa5000;
}
form div.vuetext.field, form div.vuetextarea.field {
  position: relative;
}
form div.vuetextarea.field {
  position: relative;
}
form div.vuetextarea.field.group--vuelid:before, form div.vuetextarea.field.group--vuelid:after {
  right: 10px;
  bottom: 14px;
}
form fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
form label:not(.form-check-label) {
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 900;
  margin-bottom: 5px;
}
form label:not(.form-check-label).left {
  display: block;
}
form label:not(.form-check-label).right {
  font-size: 12px;
}
form input[type=text], form input[type=date], form input[type=time], form input[type=url], form input[type=password], form input[type=email], form input[type=number], form input[type=tel] {
  border-radius: 25px;
  width: 100%;
  font-size: 1.125rem;
  line-height: 1em;
  padding: 11px 20px;
  border: 3px solid #212529;
}
form input[type=text].error, form input[type=text].holder-required, form input[type=date].error, form input[type=date].holder-required, form input[type=time].error, form input[type=time].holder-required, form input[type=url].error, form input[type=url].holder-required, form input[type=password].error, form input[type=password].holder-required, form input[type=email].error, form input[type=email].holder-required, form input[type=number].error, form input[type=number].holder-required, form input[type=tel].error, form input[type=tel].holder-required {
  border: 1px solid #dc3545;
}
form input[type=text].vuelid, form input[type=date].vuelid, form input[type=time].vuelid, form input[type=url].vuelid, form input[type=password].vuelid, form input[type=email].vuelid, form input[type=number].vuelid, form input[type=tel].vuelid {
  border: 3px solid #2f97da;
}
form input::placeholder {
  color: #7d7d7d;
}
form input:focus {
  outline: none;
}
form input[disabled] {
  color: #ccc;
  background-color: #f7f7f7;
  border: 1px solid #dad9d9 !important;
}
form textarea,
form select {
  width: 100%;
  padding: 11px 20px;
  border: 3px solid #212529;
  border-radius: 25px;
  background-color: #fff;
  font-size: 1.125rem;
  line-height: 15px;
  outline: 0;
}
form textarea.error, form textarea.holder-required,
form select.error,
form select.holder-required {
  border: 1px solid #dc3545;
}
form textarea.vuelid,
form select.vuelid {
  border: 3px solid #2f97da;
}
form select {
  border: 3px solid #212529;
  width: 100%;
}
form select option.disabled {
  color: #ccc;
}
form textarea {
  resize: vertical;
  /* forces text area to be resized vertically only */
}
form .field ul.optionset {
  padding-left: 0;
}
form .field ul.optionset li {
  list-style: none;
}
form .field ul.optionset.option__labels li {
  display: flex;
}
form .field ul.optionset.option__labels li + li {
  margin-top: 8px;
}
form .field ul.optionset.option__labels li label {
  position: relative;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  font-weight: normal;
  width: calc(100% - 60px);
}
form .field ul.optionset.option__labels.two--columns {
  margin-top: -5px;
}
form .field ul.optionset.option__labels.two--columns li {
  margin-top: 5px;
}
form .field.checkbox, form .field.radio {
  display: flex;
  flex-wrap: wrap;
}
form .field.checkbox {
  align-items: center;
}
form .field input.checkbox, form .field input.radio {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  padding: 0;
  flex-shrink: 0;
  flex-grow: 0;
}
form .field input.checkbox {
  background-color: #fff;
  border-radius: 8px;
}
form .field input[type=checkbox] + label {
  display: block;
  cursor: pointer;
  position: relative;
  line-height: 24px;
  padding: 0 0 0 39px;
  margin: 0;
}
form .field.checkbox input[type=checkbox] + label {
  height: 24px;
}
form .field input[type=checkbox] {
  display: none;
}
form .field input[type=checkbox] + label:before {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border: 1px solid #212529;
  width: 20px;
  height: 20px;
  transition: 0.2s;
}
form .field input[type=checkbox] + label:active:before {
  transform: scale(0.8);
}
form .field input[type=checkbox]:checked + label:before {
  border-color: #2f97da;
  background-color: #2f97da;
}
form .field input[type=checkbox]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}
form .field input[type=checkbox]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #e9ecef;
  border-color: #e9ecef;
}
form .field input[type=radio] {
  appearance: none;
  display: inline-block;
  border: 1px solid #212529;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  transition: 0.2s;
  margin-right: 10px;
}
form .field input[type=radio] + span {
  margin-left: 10px;
}
form .field input[type=radio]:active {
  transform: scale(0.8);
}
form .field input[type=radio]:checked {
  border-color: #2f97da;
  background-color: #2f97da;
}
form .field input[type=radio]:disabled {
  transform: scale(1);
  border-color: #aaa;
}
form .checkbox label.right,
form .radio label.right {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}
form .message {
  font-size: 14px;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  background-color: #fef4ba;
  padding: 5px 10px;
  border: 1px solid #e8c805;
  border-radius: 3px;
}
.black form .message {
  color: #000;
}
form .good {
  background-color: #ecf9d0;
  border-color: #8fbe00;
}
form .bad, form .required {
  background-color: #f9d0d0;
  border-color: #cf0000;
  color: #b80000;
}
form .btn-toolbar {
  margin-bottom: 40px;
  align-items: center;
}
form input[type=reset], form button[type=reset], form .btn-toolbar .action[type=reset] {
  padding-right: 27px !important;
}
form input[type=reset]:after, form button[type=reset]:after, form .btn-toolbar .action[type=reset]:after {
  display: none;
}
form #Remember {
  min-height: 20px;
}
form #ForgotPassword {
  clear: left;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 12px;
}
form #MemberLoginForm_LostPasswordForm .btn-toolbar:after {
  display: none;
}

div.form__row {
  display: flex;
  flex-wrap: wrap;
}
div.form__row > div.field {
  float: none;
}
div.form__row > div.holder, div.form__row:not(.form__row--simple) > div.field {
  width: auto;
  flex: 1;
}
div.form__row.form__row--simple > div.field {
  flex: 1;
}
div.form__row > .holder,
div.form__row > .field {
  transition: 0.4s ease border-left;
}
div.form__row > label {
  margin-bottom: 0;
  padding-right: 0;
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  display: inline-flex;
  align-items: center;
}
div.form__row div.checkbox label {
  display: block;
  font-size: 14px;
  line-height: 24px;
}
div.form__row + .form__row {
  margin-top: 30px;
}
div.form__row.form__row--simple div.field + .field {
  margin-top: 2px;
}
@media (min-width: 768px) {
  div.form__row.form__row--simple div.field + .field {
    margin-left: 5px;
    margin-top: 0;
  }
}
div.form__row.form__row--no-stack div.field + .field {
  margin-top: 0;
  margin-left: 5px;
}
div.form__row.CompositeField {
  display: flex;
}
@media (max-width: 991.98px) {
  div.form__row.CompositeField {
    flex-direction: column;
  }
}
div.form__row.CompositeField > div.field,
div.form__row.CompositeField > p.field,
div.form__row.CompositeField > a {
  width: auto;
  position: relative;
  flex-grow: 1;
}
@media (min-width: 768px) {
  div.form__row.CompositeField > div.field.half,
div.form__row.CompositeField > p.field.half,
div.form__row.CompositeField > a.half {
    flex-grow: 0;
    flex-basis: calc(50% - 15px);
  }
}
div.form__row.CompositeField > a {
  text-indent: 20px;
}
@media (min-width: 992px) {
  div.form__row.CompositeField div.field + .field {
    margin-top: 0;
    margin-left: 30px;
  }
}

/* purgecss end ignore */
#declineCookie {
  display: none;
}

.cookie_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cookie_inner .inner_buttons {
  display: flex;
  align-items: center;
}

.home--image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-color: #0492d4;
  background-blend-mode: multiply;
}
@media screen and (min-width: 768px) and (min-height: 800px) {
  .home--image {
    height: 600px;
  }
}
@media screen and (min-width: 992px) and (min-height: 650px) {
  .home--image {
    height: calc(100vh - 250px);
  }
}
@media screen and (min-width: 992px) and (min-height: 900px) {
  .home--image {
    height: 700px;
  }
}
@media screen and (min-width: 992px) and (min-height: 1000px) {
  .home--image {
    height: 800px;
  }
}
.home--image h1 {
  display: block;
  text-align: center;
  padding: 3px 8px;
  color: #fff;
  margin: 0;
}

.fit--image img {
  width: 100%;
  height: auto;
}

.cover--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
div.row .cover--image.col-lg-6:first-child {
  padding-left: 0;
  padding-right: 0;
}

.client__logo {
  flex-grow: 0;
  height: 60px;
}
@media (min-width: 768px) {
  .client__logo {
    height: 110px;
  }
}
.client__logo img {
  object-fit: contain;
}

body.HomePage .solutions-holder,
body.SolutionOverview .solutions-holder,
body.Sector .solutions-holder {
  padding-top: 0;
  margin-top: -60px;
}
@media (min-width: 768px) {
  body.HomePage .solutions-holder,
body.SolutionOverview .solutions-holder,
body.Sector .solutions-holder {
    margin-top: -100px;
  }
}
body.HomePage .solutions-holder + .markets-holder,
body.SolutionOverview .solutions-holder + .markets-holder,
body.Sector .solutions-holder + .markets-holder {
  padding-top: 0;
}

body.SectorOverview .markets-holder {
  padding-top: 0;
  margin-top: -60px;
}
@media (min-width: 768px) {
  body.SectorOverview .markets-holder {
    margin-top: -100px;
  }
}

.negative-margin-top {
  padding-top: 0;
  margin-top: -60px;
}
@media (min-width: 768px) {
  .negative-margin-top {
    margin-top: -100px;
  }
}

.title-icon-holder {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.title-icon-holder .icon--small {
  flex-shrink: 0;
  margin-bottom: 8px;
}
h2 .title-icon-holder {
  font-size: 2rem;
  word-break: break-word;
}
.title-icon-holder.title-icon-holder--smaller {
  font-size: 1.5rem;
}

.blue-header__block {
  background-color: #fff;
  border: 1px solid #fff;
}
.blue-header__block .bg-gradient--main.block--padding.block--narrow, .block--narrow .blue-header__block .bg-gradient--main.block--padding {
  padding: 40px 20px;
}
@media (min-width: 992px) {
  .blue-header__block .bg-gradient--main {
    min-height: 185px;
  }
}
.blue-header__block .bg-gradient--main h2 {
  margin-bottom: 0;
  word-break: break-word;
}
.blue-header__block .bg-gradient--main, .blue-header__block .bg-gradient--main a {
  color: #fff;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .blue-header__block .bg-gradient--main {
    min-height: 220px;
  }
}
@media (min-width: 992px) {
  .blue-header__block .block--excerpt p:last-of-type {
    margin-bottom: 50px;
  }
}

.blue__block {
  background: #0492d4;
  color: #fff;
}
.blue__block a:not(.btn) {
  color: #fff;
}
.blue__block ul {
  padding-left: 19px;
  font-size: 13px;
  line-height: 1.61em;
}
.blue__block ul a {
  display: block;
}
.blue__block ul a:hover {
  color: #212529;
}

.bg-gradient--main {
  background: radial-gradient(at 120% 50%, #0492d4 0%, #046ac3 100%);
}

/** --------- HIGHLIGHTED --------- */
.block--vertical-centered {
  display: flex;
  justify-content: center;
  height: 100%;
}

.block--vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.highlighted__block--image {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .highlighted__block--image {
    display: block;
  }
}

/** --------- MARKETS --------- */
.icon--blue img {
  fill: #2f97da;
}
.icon--small img {
  object-fit: contain;
  height: 34px;
}
.icon--small img + img {
  margin-left: 40px;
}
.icon--small-40 img {
  object-fit: contain;
  height: 44px;
}
.icon--small-medium img {
  object-fit: contain;
  height: 61px;
}
.icon--medium img {
  object-fit: contain;
  height: 100px;
}
.icon--inline {
  display: inline-block;
  width: 24px;
  height: 100%;
}
@media (min-width: 1200px) {
  .icon--inline {
    width: 60px;
  }
}

blockquote.review--text {
  margin: 30px 0 10px 0;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
}
@media (min-width: 992px) {
  blockquote.review--text {
    font-size: 1.5rem;
  }
}

.review--company {
  margin-bottom: 35px;
  text-align: center;
}

.stars {
  display: flex;
  justify-content: center;
  margin: 0;
}
.stars .star {
  width: 30px;
  height: 30px;
  background: transparent no-repeat 0 0;
  background-size: contain;
  background-image: url("/_resources/themes/topsystems/images/bliksem_blauw_rgb_filled.svg");
}
.stars .star.no {
  background-image: url("/_resources/themes/topsystems/images/bliksem_blauw_rgb.svg");
}
.stars + .star {
  margin-left: 5px;
}

.whitepaper__block--text {
  font-size: 13px;
}

.whitepaper__block--image {
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (min-width: 1660px) {
  .whitepaper__form-holder h3 {
    width: 450px;
  }
}
.whitepaper__form-holder form div.field + .field {
  margin-top: 20px;
}
.whitepaper__form-holder form .btn-toolbar {
  margin-top: 20px;
  margin-bottom: 0;
}

.whitepaper__block-holder input[type=submit], .whitepaper__block-holder .btn-toolbar .action {
  width: 100%;
}

/***---------------- OLD -------


.market__block--intro {
  text-align: center;
  margin: 0 auto;
  max-width: 300px;

  h2 {
    max-width: 290px;
  }
}

.market__block {
  display: block;
  background-color: $blueShadeLight;

  &.Pos2 {
    background-color: $blueShadeMedium;
  }

  &.Pos3 {
    background-color: $blueShadeDark;
  }
}

.market__block--link {
  h2 {
    @include transition($btn-transition);
  }

  &:hover {
    text-decoration: none;

    h2 {
      color: $black;
    }
  }
}

.market__block--image {
  padding-top: 100%;
  background-size: cover;
}

.market__block--text {
  padding: 20px 15px;

  h2 {
    font-size: 20px;
    margin: 0;
    color: $white;
    // todo white button
  }
}

// --------- SHOWCASES ---------
.case__block--intro {
  text-align: center;
  margin: 0 auto;
  max-width: 300px;

  h2 {
    max-width: 290px;
  }
}

.case__block {
  display: block;
}

.case__block--text {
  display: flex;
  transform: translateY(-50%);

  .case__block--inner {
    @include transition($btn-transition);

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  .case__block--inner {
    display: block;
    color: $white;
    background-color: $blueShadeDark;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 70px;
    text-align: center;

    h2 {
      font-family: $font-family-base;
      font-size: 20px;
      line-height: 30px;
      margin: 0;

      strong {
        @include h1font;

      }

    }
  }
}

.case__block--image {
  padding-top: 500px;
  background-size: cover;
}

.case__block-holder {
}

// --------- CONTACT BLOCK ---------

.background-image-holder {
  background-size: cover;
  color: $white;
  text-align: center;
  background-position: center;
}

.background-image-holder--fixed {
  // other than IOS
  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed;
  }
}

.contact__block--intro {
  display: flex;
  min-height: 600px;
  height: 50vh;
  justify-content: center;
  flex-direction: column;
}

// --------- GENERAL BLOCK ---------
.general__block-holder {
}

.block__link {
  &:hover {
    text-decoration: none;
  }
}

*/
.section__footer {
  position: relative;
  background-color: #2f97da;
  color: #fff;
  padding: 40px 0;
}
@media (min-width: 1660px) {
  .section__footer {
    padding: 130px 0;
  }
}
.section__footer a {
  color: #fff;
}
.section__footer .navbar-brand {
  margin-top: -24px;
  width: 100%;
  height: 150px;
}
.section__footer .navbar-brand img {
  object-position: left;
}
@media (min-width: 1660px) {
  .section__footer .navbar-brand {
    margin-bottom: 20px;
    width: 525px;
    height: 172px;
  }
}

.footer__description {
  max-width: 525px;
}
.footer__description a {
  text-decoration: underline;
}

.footer-block__title {
  font-size: 1.125rem;
  margin-bottom: 22px;
}

.footer-block__content ol, .footer-block__content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-block__content ol li li, .footer-block__content ul li li {
  margin-left: 19px;
}

#mc_embed_signup #mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;
}
#mc_embed_signup input {
  border: 0;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#mc_embed_signup input.email {
  color: #fff;
  background-color: #2f97da;
  font-size: 16px;
  padding: 8px 10px;
  margin-bottom: 10px;
  max-width: 250px;
  border: 3px solid #fff;
  border-radius: 20px;
}
#mc_embed_signup input.email::placeholder {
  color: #fff;
  font-weight: bold;
}
#mc_embed_signup input.button {
  background-color: #212529;
  color: #fff;
  border-radius: 5px;
  flex-shrink: 0;
  flex-basis: 100px;
  min-width: 150px;
}

.social-links {
  display: flex;
}

.social__link {
  display: block;
  background: no-repeat 0 0 #fff;
  background-size: contain;
  text-indent: -999px;
  overflow: hidden;
  width: 56px;
  border-radius: 28px;
}
.social__link:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.social__link + .social__link {
  margin-left: 10px;
}

.social__link--inline {
  display: inline-block;
  vertical-align: bottom;
  background: no-repeat 0 0 #fff;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.social__link--facebook {
  background-image: url("/_resources/themes/topsystems/images/social-facebook.svg");
}

.social__link--linkedin {
  background-image: url("/_resources/themes/topsystems/images/social-linkedin.svg");
}

.social__link--instagram {
  background-image: url("/_resources/themes/topsystems/images/social-instagram.svg");
}

.social__link--youtube {
  background-image: url("/_resources/themes/topsystems/images/social-youtube.svg");
}

.social__link--twitter {
  background-image: url("/_resources/themes/topsystems/images/social-twitter.svg");
}

.footer-border--left {
  position: relative;
}
@media (min-width: 576px) {
  .footer-border--left-sm {
    padding-left: 30px;
    border-left: 1px solid white;
  }
}
@media (min-width: 768px) {
  .footer-border--left-md {
    padding-left: 30px;
    border-left: 1px solid white;
  }
}
@media (min-width: 992px) {
  .footer-border--left-lg {
    padding-left: 30px;
    border-left: 1px solid white;
  }
}
.footer-border--left:before {
  content: "";
}

.bottom {
  padding-top: 25px;
  padding-bottom: 25px;
}
.bottom, .bottom a {
  color: #fff;
}
.bottom .bottom__menu {
  text-align: right;
}
.bottom .bottom__menu a + a {
  margin-left: 22px;
}

.newsletter__holder {
  position: relative;
}
.newsletter__holder .newsletter__submit {
  position: absolute;
  text-indent: -9999px;
  overflow: visible;
  right: 0;
  top: 0;
  z-index: 2;
  border: none !important;
}
.newsletter__holder .newsletter__submit:hover:after {
  border-color: #fff;
}

._form_5 form {
  padding: 0 !important;
  margin: 0;
}
._form_5 form ._form_element label {
  font-size: 1.125rem !important;
}
._form_5 form #email {
  color: #fff !important;
  background-color: #2f97da !important;
  font-size: 16px !important;
  padding: 8px 10px !important;
  margin-bottom: 10px !important;
  max-width: 250px !important;
  border: 3px solid #fff !important;
  border-radius: 20px !important;
}
._form_5 form #email::placeholder {
  color: #fff;
  font-weight: bold;
}
._form_5 form ._button-wrapper > button._submit {
  border-radius: 20px !important;
  padding: 7px 10px !important;
  font-family: "Avenir", Arial, Helvetica, sans-serif !important;
}

.slider__nav .slick-slide {
  margin: 0 10px;
}
.slider__nav .slick-list {
  margin: 0 -10px;
}
.slider__gallery[data-browse=true] + .slider__nav {
  margin-left: 15px;
  margin-right: 15px;
}
@media (min-width: 992px) {
  .slider__gallery[data-browse=true] + .slider__nav {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.slider__gallery {
  position: relative;
}
.slider__gallery[data-browse=true] {
  margin-left: 15px;
  margin-right: 15px;
}
@media (min-width: 992px) {
  .slider__gallery[data-browse=true] {
    margin-left: 30px;
    margin-right: 30px;
  }
}
.slider__gallery.slick-dotted.slick-slider {
  margin-bottom: 50px;
}
.slider__gallery[data-multiple] .slick-list {
  margin: 0 -26px;
}
.slider__gallery[data-multiple] .slick-slide {
  margin: 0 26px;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 5;
  overflow: hidden;
  text-indent: -999px;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  height: 15px;
  width: 15px;
  border-left: 2px solid #1C6AC3;
  border-bottom: 2px solid #1C6AC3;
  display: block;
}
.slick-arrow:active {
  background-color: transparent;
}
.slick-arrow.slick-prev {
  left: -20px;
  transform: rotate(45deg);
}
@media (min-width: 992px) {
  .slick-arrow.slick-prev {
    left: -40px;
  }
}
.slick-arrow.slick-next {
  right: -20px;
  transform: rotate(225deg);
}
@media (min-width: 992px) {
  .slick-arrow.slick-next {
    right: -40px;
  }
}

.slick-dots {
  bottom: -40px;
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li button:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 3px solid #1C6AC3;
  border-radius: 10px;
  background-color: #fff;
}
.slick-dots li.slick-active button:after {
  background-color: #1C6AC3;
}

.slider__carousel {
  position: relative;
}
.slider__carousel .slick-track {
  display: flex;
}
.slider__carousel .slick-track .slick-slide {
  display: flex;
  height: auto;
  justify-self: center;
}
.slider__carousel .slick-slide {
  flex-grow: 1;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .slider__carousel .slick-slide > div,
.slider__carousel .slick-slide > div .simple-slide,
.slider__carousel .slick-slide > div .simple-slide .row,
.slider__carousel .slick-slide > div .simple-slide .row > div {
    width: 100%;
    height: 100%;
  }
}
.slider__carousel .slick-slide .brand__content {
  margin-bottom: 20px;
}
.slider__carousel .slick-slide .btn {
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 3px;
}
@media (max-width: 575.98px) {
  .container .slider__carousel .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .container .slider__carousel .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.slider__carousel.slider--grayscale .slick-arrow:before, .slider__carousel.slider--grayscale .slick-arrow:after {
  border-left: 2px solid #212529;
}
@media (max-width: 991.98px) {
  .slider__carousel.slider--huge-images .slick-arrow.slick-next, .slider__carousel.slider--huge-images .slick-arrow.slick-prev {
    top: 150px;
    margin-top: -50px;
  }
}
.slider__carousel.slider--huge-images .slick-arrow:before, .slider__carousel.slider--huge-images .slick-arrow:after {
  border-left: 2px solid #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
@media (min-width: 992px) {
  .slider__carousel.slider--huge-images .slick-arrow.slick-prev:before, .slider__carousel.slider--huge-images .slick-arrow.slick-prev:after {
    border-left: 2px solid #212529;
  }
}

.slider__content {
  margin-bottom: 10px;
}

.slider-navigation {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 991.98px) {
  .slider-navigation {
    padding-bottom: 10px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .slider-navigation a {
    flex-shrink: 0;
  }
}
.slider-navigation.rows-mobile {
  overflow-x: hidden;
  flex-direction: column;
}
@media (min-width: 768px) {
  .slider-navigation.rows-mobile {
    flex-direction: row;
  }
}
.slider-navigation a {
  display: flex;
  text-decoration: none;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
}
@media (min-width: 768px) {
  .slider-navigation a {
    min-height: 50px;
  }
  .slider-navigation a.regular, .slider-navigation a.quarter {
    font-size: 14px;
    margin-right: 30px;
    margin-bottom: 20px;
    width: auto;
  }
  .slider-navigation a.regular:last-of-type, .slider-navigation a.quarter:last-of-type {
    margin-right: 0;
  }
  .slider-navigation a.quarter {
    flex-shrink: 1;
    flex-basis: calc(20% - 30px);
  }
}
.slider-navigation a img {
  display: block;
  width: auto;
  max-height: 50px;
  vertical-align: middle;
}
.slider-navigation a.active {
  border-bottom: 2px solid #1C6AC3;
}
@media (max-width: 767.98px) {
  .slider-navigation.rows-mobile a {
    margin-left: 0;
  }
  .slider-navigation.rows-mobile a + a {
    margin-top: 30px;
  }
}

.slider--huge-image {
  background-color: #EDEEEE;
}
.slider--huge-image .simple-slide {
  min-height: 600px;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  padding: 0 0 60px 0;
}
@media (min-width: 768px) {
  .slider--huge-image .simple-slide {
    padding: 60px 0;
    flex-direction: row;
  }
}
@media (max-width: 767.98px) {
  .slider--huge-image h2 {
    font-size: 1.3rem;
  }
  .slider--huge-image h3 {
    font-size: 1.1rem;
  }
}
.slider--huge-image .filled-image {
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .slider--huge-image .filled-image {
    margin-bottom: 0;
    height: auto;
    width: auto;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.slider--has-shadows .slick-slide {
  padding: 25px 0;
}

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ad.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ae.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/af.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ag.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ai.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/al.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/al.svg);
}

.flag-icon-am {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/am.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ao.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/aq.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/as.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/as.svg);
}

.flag-icon-at {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/at.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/at.svg);
}

.flag-icon-au {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/aw.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ax.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/az.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ba.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bb.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bd.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bf.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bg.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bh.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bi.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bj.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bl.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bm.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bn.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bo.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bq.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bs.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bt.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bv.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bw.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/by.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/bz.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cc.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cd.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cf.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cg.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ci.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ck.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cl.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cm.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cn.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/co.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cr.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cu.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cv.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cw.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cx.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cy.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/cz.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/dj.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/dk.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/dm.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/do.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/dz.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ec.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ee.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/eg.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/eh.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/er.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/er.svg);
}

.flag-icon-es {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/es.svg);
}

.flag-icon-et {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/et.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/fj.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/fk.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/fm.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/fo.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ga.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gd.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ge.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gg.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gh.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gi.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gl.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gm.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gn.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gp.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gq.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gr.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gs.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gt.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gu.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gw.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gy.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/hm.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/hn.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ht.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/hu.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/il.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/il.svg);
}

.flag-icon-im {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/im.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/im.svg);
}

.flag-icon-in {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/in.svg);
}

.flag-icon-io {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/io.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/iq.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ir.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/is.svg);
}

.flag-icon-it {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/it.svg);
}

.flag-icon-je {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/je.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/jm.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/jo.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ke.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/kg.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/kh.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ki.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/km.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/kn.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/kp.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/kw.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ky.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/kz.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/la.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/lb.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/lc.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/li.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/lk.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/lr.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ls.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/lt.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/lv.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ly.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ma.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mc.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/md.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/md.svg);
}

.flag-icon-me {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/me.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mf.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mg.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mh.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mk.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ml.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mm.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mn.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mo.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mp.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mq.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mr.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ms.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mt.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mu.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mv.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mw.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/my.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/mz.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/na.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/nc.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/nf.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ng.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ni.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/no.svg);
}

.flag-icon-np {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/np.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/nr.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/nu.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/nz.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/om.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pa.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pe.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pf.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pg.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ph.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pk.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pm.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pn.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pr.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ps.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/pw.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/py.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/qa.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/re.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/rs.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/rw.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sb.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sc.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sd.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sh.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/si.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sj.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sk.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sl.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sm.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sn.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/so.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sr.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ss.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/st.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sv.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sx.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sy.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/sz.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tc.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/td.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tf.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tg.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/th.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tj.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tk.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tl.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tm.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tn.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/to.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tt.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tv.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tw.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/tz.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ua.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ug.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/um.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/um.svg);
}

.flag-icon-us {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/uy.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/uz.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/va.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/vc.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ve.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/vg.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/vi.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/vn.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/vu.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/wf.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ws.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/ye.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/yt.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/za.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/zm.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/zw.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/zw.svg);
}

.flag-icon-es-ca {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/es-ca.svg);
}
.flag-icon-es-ca.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/es-ca.svg);
}

.flag-icon-eu {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gb-nir.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gb-sct.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/gb-wls.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/un.svg);
}

.flag-icon-xk {
  background-image: url(/_resources/themes/topsystems/images/flags/4x3/xk.svg);
}
.flag-icon-xk.flag-icon-squared {
  background-image: url(/_resources/themes/topsystems/images/flags/1x1/xk.svg);
}

/**
 * Flags from https://flagpedia.net/
 */
.flags .flag-icon {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: contain;
  overflow: hidden;
  text-indent: -9999px;
}

.iframe__holder {
  /* purgecss ignore current */
  margin-bottom: 0;
  padding-bottom: 0;
}
.iframe__holder iframe {
  display: block;
  width: 100%;
}

.opening__times tr td:first-child {
  padding-right: 20px;
}
@media (min-width: 768px) {
  .opening__times tr td:first-child {
    padding-right: 50px;
  }
}
.opening__times tr td:nth-child(2) {
  white-space: nowrap;
}

#ContactForm_ContactForm input[type=submit], #ContactForm_ContactForm .btn-toolbar .action {
  width: 100%;
}

.brand__content {
  font-size: 16px;
}

.image--holder img {
  flex-shrink: 0;
}

.jump-navigation {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .jump-navigation {
    flex-direction: row;
  }
}
.jump-navigation a {
  text-decoration: none;
  padding-bottom: 10px;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  font-weight: 900;
}
@media (min-width: 768px) {
  .jump-navigation a.quarter {
    flex-basis: 25%;
  }
}
.jump-navigation a + a {
  margin-top: 0;
}
@media (min-width: 768px) {
  .jump-navigation a + a {
    margin-top: 0;
    margin-left: 50px;
  }
}

.even-odd__block {
  position: relative;
  background-color: #fff;
}

.even-odd__block--padding {
  padding: 40px 0;
}
@media (min-width: 768px) {
  .even-odd__block--padding {
    padding: 40px;
  }
}

.even-odd__block--content-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}
@media (min-width: 992px) {
  .even-odd__block--content-holder {
    min-height: 500px;
  }
}

.even-odd__block--image {
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  width: 100%;
  height: 300px;
}
@media (min-width: 768px) {
  .even-odd__block--image {
    height: auto;
    width: auto;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .even .even-odd__block--image {
    left: 0;
    right: 50%;
  }
}

.pdf__download_block {
  display: none;
}
.pdf__download_block.shown {
  display: block;
}

@media (max-width: 991.98px) {
  .showcase__table td, .showcase__table th {
    display: block;
  }
}

.slick-slide .single__project {
  height: 100%;
}

.project__logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto 0;
}
@media (min-width: 768px) {
  .project__logos {
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.project__logos img {
  width: 225px;
  height: 50px;
  object-fit: contain;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .project__logos img {
    margin-top: 40px;
    height: 75px;
  }
  .project__logos img + img {
    margin-left: 40px;
  }
}
.project__logos.project__logos--small {
  width: 100%;
  max-width: none;
  justify-content: space-between;
}
.project__logos.project__logos--small img {
  width: calc(50% - 10px);
  height: 40px;
  margin-bottom: 20px;
}
.project__logos.project__logos--small img + img {
  margin-left: 0;
}

.project__filters form {
  position: relative;
}
@media (min-width: 768px) {
  .project__filters .btn-toolbar {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(100%) translateX(26px) translateY(-100%) translateY(-60px);
  }
}
@media (min-width: 1200px) {
  .project__filters .btn-toolbar {
    right: 33%;
  }
}

.step__item {
  display: flex;
  position: relative;
  flex-direction: column;
}
@media (min-width: 768px) {
  .step__item {
    flex-direction: row;
  }
}
.step__item .icon {
  flex-shrink: 0;
  width: 44px;
  margin: 0 43px 20px 0;
}
.step__item + .step__item {
  margin-top: 45px;
}
.step__item div p:last-of-type {
  margin-bottom: 0;
}
.step__item .step__item--line {
  position: relative;
}
.step__item .step__item--line .icon--small-40 {
  margin-top: -3px;
}
@media (min-width: 768px) {
  .step__item .step__item--line .icon--small-40 {
    margin-top: -5px;
  }
}
@media (min-width: 992px) {
  .step__item .step__item--line .icon--small-40 {
    margin-top: -3px;
  }
}

.project__details {
  position: relative;
  border: 1px solid #fff;
}

.push--over {
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 0;
  transform: translateY(-50%);
}
.push--over .btn-primary:hover {
  background-color: #FFF;
}
@media (max-width: 767.98px) {
  .push--over .btn {
    font-size: 0.984375rem;
    padding-left: 18px;
    padding-right: 40.5px !important;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .push--over .btn:after {
    right: 20.25px;
  }
}

.square__item {
  display: block;
}

.square__item--link {
  color: #212529;
}
.square__item--link, .square__item--link:hover {
  color: #212529;
  text-decoration: none;
}

.square__item--title {
  font-size: 16px;
  margin-bottom: 0;
}

.square__item--text {
  padding: 15px 40px 40px 40px;
}

.square__item--footer {
  white-space: normal;
}
@media (min-width: 576px) {
  .square__item--footer {
    white-space: nowrap;
  }
}
@media (min-width: 768px) {
  .square__item--footer {
    white-space: normal;
  }
}
@media (min-width: 1660px) {
  .square__item--footer {
    white-space: nowrap;
  }
}

.block__column {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.block__column .block__column--content {
  flex-grow: 1;
  padding-bottom: 62px;
  position: relative;
}
.block__column .block__column--content a.btn:last-child {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.arrow--up {
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  text-indent: -999px;
  overflow: hidden;
}
.arrow--up:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-top: 2px solid #2f97da;
  border-right: 2px solid #2f97da;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -5px;
  transform: rotate(-45deg);
}

.process-step {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 20px 0 0;
  word-break: break-word;
}
@media (min-width: 768px) {
  .process-step {
    padding: 0 20px;
  }
}
.process-step .border__holder {
  border-bottom: 1px solid #212529;
}
.process-step .process-step__item--line {
  position: relative;
  padding-top: 10px;
}
.process-step .process-step__item--line:after {
  content: "";
  border-right: 2px solid #2f97da;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.process-step .process-step__item--line.first:after {
  top: 50px;
}
.process-step .process-step__item--line.last:after {
  top: 0;
  bottom: calc(100% - 50px);
}
.process-step:first-of-type .border__holder {
  border-top: 1px solid #212529;
}
.process-step + .process-step__item {
  margin-top: 0;
}
.process-step .process-step__inner {
  padding: 20px 0 20px 0;
}
@media (min-width: 768px) {
  .process-step .process-step__inner {
    padding: 37px 100px 37px 0;
  }
}
.process-step .process-step__icon {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 6px solid #2f97da;
  border-radius: 50px;
  margin: 0 auto;
  z-index: 5;
  width: 60px;
  height: 60px;
}
@media (max-width: 991.98px) {
  .process-step .process-step__icon {
    position: absolute;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
  }
  .process-step .process-step__icon.icon--small-40 img {
    height: 34px;
  }
}
@media (min-width: 992px) {
  .process-step .process-step__icon {
    border: 10px solid #2f97da;
    width: 90px;
    height: 90px;
  }
}
.process-step h3 {
  margin-bottom: 0;
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .process-step h3 {
    font-size: 2rem;
  }
}
.process-step h3 a {
  display: block;
  color: #212529;
  padding: 0 20px 0 0;
}
@media (min-width: 768px) {
  .process-step h3 a {
    padding: 0 25px 0 0;
  }
}
.process-step h3 a:hover {
  text-decoration: none;
}
.process-step h3 a:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  content: "+";
  width: 10px;
  height: 10px;
  z-index: 9;
  right: 15px;
  top: 35px;
}
@media (min-width: 768px) {
  .process-step h3 a:after {
    right: 25px;
    top: 40px;
  }
}
.process-step .process-step__more {
  display: none;
  padding-top: 10px;
  padding-bottom: 100px;
}
.process-step .process-step__more a.btn {
  visibility: hidden;
  position: absolute;
  left: -20px;
}
@media (min-width: 768px) {
  .process-step .process-step__more {
    padding-bottom: 0;
  }
  .process-step .process-step__more a.btn {
    position: relative;
    left: auto;
  }
}
.process-step .process-step__more p:last-child {
  margin-bottom: 0;
}
.process-step.opened .process-step__more {
  display: block;
}
.process-step.opened.opening .process-step__more a.btn {
  visibility: visible;
}
.process-step.opening h3 a:after {
  content: "-";
}

.align-process-step {
  margin-left: 20px;
}
@media (min-width: 992px) {
  .align-process-step {
    margin-left: 26px;
  }
}
@media (min-width: 1200px) {
  .align-process-step {
    margin-left: 40px;
  }
}
@media (min-width: 1660px) {
  .align-process-step {
    margin-left: 78px;
  }
}
@media (min-width: 1784px) {
  .align-process-step {
    margin-left: 90px;
  }
}

@media (min-width: 768px) {
  .md-border-right-black {
    border-right: 1px solid black;
  }
}

@media (min-width: 768px) {
  .md-border-left-black {
    border-left: 1px solid black;
  }
}

@media (max-width: 767.98px) {
  .mobile-border-top-black {
    padding-top: 10px;
    border-top: 1px solid black;
  }
}

@media (max-width: 767.98px) {
  .mobile-border-bottom-black {
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }
}

.even-odd__square {
  position: relative;
  background-color: #fff;
}
.even-odd__square.even {
  background-color: #edeeee;
}

.square__blocks .square__block {
  background-color: #fff;
}
.square__blocks .square__block.last {
  background-color: #edeeee;
}
@media (min-width: 768px) {
  .square__blocks .row.even .square__block {
    background-color: #edeeee;
  }
  .square__blocks .row.even .square__block.last {
    background-color: #fff;
  }
}

.square__block--content-holder {
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.square__block--padding {
  padding: 40px 40px;
}
@media (min-width: 768px) {
  .square__block--padding {
    padding: 80px 40px;
  }
}
@media (min-width: 992px) {
  .square__block--padding {
    padding: 120px 40px;
  }
}

.history__item {
  position: relative;
  padding-bottom: 100px;
}
@media (max-width: 575.98px) {
  .history__item:after {
    content: "";
    border-right: 2px solid #2f97da;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    height: 90px;
    bottom: 10px;
    z-index: 1;
  }
  .history__item:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #2f97da;
    background-color: #FFF;
    position: absolute;
    border-radius: 10px;
    left: 50%;
    margin-left: -10px;
    bottom: 10px;
    z-index: 2;
  }
  .history__item.last:after {
    bottom: 0;
    height: 100px;
  }
  .history__item.last:before {
    display: none;
  }
}
@media (min-width: 992px) {
  .history__item {
    margin-left: 75px;
    margin-right: 75px;
  }
}
@media (min-width: 576px) {
  .history__item:after {
    content: "";
    border-right: 2px solid #2f97da;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .history__item:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #2f97da;
    background-color: #FFF;
    position: absolute;
    border-radius: 10px;
    left: 50%;
    margin-left: -10px;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
  .history__item.first:after {
    top: calc(50% - 50px);
  }
  .history__item:before {
    top: calc(50% - 60px);
  }
}

@media (min-width: 576px) {
  .history__item--col:nth-child(1) {
    padding-right: 50px;
  }
  .history__item--col:nth-child(2) {
    padding-left: 50px;
  }
}
@media (min-width: 768px) {
  .history__item--col:nth-child(1) {
    padding-right: 100px;
  }
  .history__item--col:nth-child(2) {
    padding-left: 100px;
  }
}

.history__image--holder {
  display: flex;
  justify-content: center;
  margin: auto;
}
.history__image--holder:after {
  content: "";
  position: absolute;
}
.history__image--holder img {
  width: auto;
  z-index: 3;
}

@media (min-width: 576px) {
  .history__image--left {
    position: relative;
  }
  .history__image--left:after {
    content: "";
    border-top: 2px solid #2f97da;
    position: absolute;
    right: 0;
    left: 50%;
    top: 50%;
    margin-top: -1px;
    z-index: 1;
  }

  .history__image--right {
    position: relative;
  }
  .history__image--right:after {
    content: "";
    border-top: 2px solid #2f97da;
    position: absolute;
    left: 0;
    right: 50%;
    top: 50%;
    margin-top: -1px;
    z-index: 1;
  }
}
.history__year--holder {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 576px) {
  .history__year--holder {
    min-height: 300px;
  }
}

.history__year--title {
  color: #2f97da;
}

.history__year--content {
  margin-bottom: 20px;
  font-size: 0.984375rem;
}
@media (min-width: 576px) {
  .history__year--content {
    margin-bottom: 0;
  }
}

.history__item .history__image--holder {
  order: 1;
}
.history__item .history__year--holder {
  order: 0;
}
@media (min-width: 576px) {
  .history__item .history__image--holder, .history__item .history__year--holder {
    order: initial;
  }
}

.specialist,
.specialist__advice {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.specialist--compact,
.specialist__advice {
  align-items: center;
}
.specialist--compact .specialist__image,
.specialist__advice .specialist__image {
  flex-shrink: 0;
}
.specialist--compact .specialist__image img,
.specialist__advice .specialist__image img {
  width: 80px;
  height: auto;
  border-radius: 40px;
  border: 1px solid #EDEEEE;
}

.specialist__advice .specialist--name {
  font-size: 0.9rem;
}

.specialist__content {
  margin-left: 20px;
}
@media (min-width: 992px) {
  .specialist__content {
    margin-left: 40px;
  }
}

.specialist--extended {
  color: #fff;
  background-color: #2f97da;
  margin-top: 3rem !important;
  padding: 1rem 1.25rem 1.25rem 1.25rem;
  font-size: 0.84375rem;
  flex-direction: column;
}
@media (min-width: 576px) {
  .specialist--extended {
    flex-direction: row;
  }
}
.specialist--extended .specialist__content {
  margin-left: 0;
}
@media (min-width: 576px) {
  .specialist--extended .specialist__content {
    margin-left: 40px;
  }
}
.specialist--extended a {
  color: #fff;
}
.specialist--extended .h6 {
  font-size: 0.95625rem;
}
.specialist--extended .specialist__image {
  margin-top: -3rem;
}

.specialist__logo img {
  height: 68px;
}

.specialist--name {
  font-weight: bold;
}

[v-cloak] {
  display: none;
}

.quote__support {
  display: flex;
}
@media (min-width: 768px) {
  .quote__support {
    margin-top: 0;
    width: 275px;
  }
}
.quote__support img {
  display: inline-block;
  width: 40px;
  height: auto;
  margin-right: 20px;
}

#quoteApp {
  position: relative;
}
#quoteApp.loading:before {
  z-index: 100;
  content: "";
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

body.Quote.index, body.Quote.complete {
  background-color: #eceded;
}
body.Quote #mainContainer {
  padding-bottom: 0;
}
body.Quote .btn-toolbar .action {
  position: relative;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  background-color: #fa5000;
}
body.Quote .page__centered-block--content {
  min-height: 0;
}
body.Quote .page__centered-block--small {
  text-align: left;
  width: 50%;
  min-width: 300px;
  margin: 0 auto;
  max-width: 475px;
}
body.Quote .page__centered-block--small ol {
  margin: 0 auto;
  width: fit-content;
}
body.Quote .form--alert {
  display: block;
  position: absolute;
  left: 22px;
  bottom: -22px;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
}
body.Quote textarea.vuetextarea {
  margin: 0;
  display: block;
}
body.Quote .field.vuetextarea {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body.Quote #QuoteForm_QuoteForm_Name_Holder {
    float: left;
    width: calc(50% - 26px);
  }
}
@media (min-width: 1660px) {
  body.Quote #QuoteForm_QuoteForm_Name_Holder {
    width: auto;
    float: none;
  }
}
@media (min-width: 768px) {
  body.Quote #QuoteForm_QuoteForm_Company_Holder {
    margin-top: 0;
    float: right;
    width: calc(50% - 26px);
  }
}
@media (min-width: 1660px) {
  body.Quote #QuoteForm_QuoteForm_Company_Holder {
    margin-top: 30px;
    width: auto;
    float: none;
  }
}
@media (min-width: 768px) {
  body.Quote #QuoteForm_QuoteForm_Email_Holder {
    margin-top: 30px;
    margin-bottom: 30px;
    float: left;
    width: calc(50% - 26px);
  }
}
@media (min-width: 1660px) {
  body.Quote #QuoteForm_QuoteForm_Email_Holder {
    width: auto;
    float: none;
  }
}
@media (min-width: 768px) {
  body.Quote #QuoteForm_QuoteForm_Phone_Holder {
    margin-top: 30px;
    margin-bottom: 30px;
    float: right;
    width: calc(50% - 26px);
  }
}
@media (min-width: 1660px) {
  body.Quote #QuoteForm_QuoteForm_Phone_Holder {
    width: auto;
    float: none;
  }
}
@media (min-width: 768px) {
  body.Quote #QuoteForm_QuoteForm_Message_Holder {
    clear: both;
  }
}
body.Quote #QuoteForm_QuoteForm_Referrer_Holder {
  position: relative;
}
@media (min-width: 768px) {
  body.Quote #QuoteForm_QuoteForm_Referrer_Holder {
    margin-bottom: 30px;
  }
}
body.Quote #QuoteForm_QuoteForm_ReferrerOther_Holder {
  margin-top: -20px !important;
  margin-bottom: 20px;
}
body.Quote .btn-toolbar {
  flex-direction: column;
  align-items: start;
}
body.Quote .btn-toolbar .action {
  width: 100%;
  background-color: #fa5000;
  background-image: none;
  border: 3px solid #fa5000;
}
@media (min-width: 1660px) {
  body.Quote .btn-toolbar {
    width: 33%;
    padding-left: 26px;
    transform: translateY(-30px);
    margin-left: auto;
  }
}
@media (min-width: 1784px) {
  body.Quote .btn-toolbar {
    transform: translateY(-5px);
  }
}
body.Quote .btn-toolbar .form__under {
  display: block;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .quote__indicator-text {
    margin-right: 30px;
  }
}
.quote__indicator-text p:last-of-type {
  margin-bottom: 0;
}

.quote__indicator {
  display: block;
  border: 3px solid #2f97da;
  border-radius: 16px;
  width: 150px;
  height: 60px;
  position: relative;
}
.quote__indicator:before {
  display: block;
  content: "";
  height: 24px;
  position: absolute;
  top: 15px;
  border-radius: 0 9px 9px 0;
  background-color: #2f97da;
  width: 13px;
  right: -13px;
}

.quote__indicator--frame {
  background: white;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  border: 3px solid white;
  overflow: hidden;
}

.progress__bar {
  display: block;
  background-color: #2f97da;
  height: 100%;
  transition: width 500ms ease;
}

.progress__number {
  font-weight: bold;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  flex-direction: column;
  display: none;
  font-size: 24px;
  right: -90px;
}
@media (min-width: 992px) {
  .progress__number {
    display: flex;
  }
}

ul.lightning, .ul--lightning ul {
  padding-left: 0;
  list-style: none;
}
ul.lightning li, .ul--lightning ul li {
  line-height: 28px;
  background: url("/_resources/themes/topsystems/images/ico-flash-black.svg") 0 3px no-repeat;
  background-size: 16px auto;
  padding-left: 25px;
}
ul.lightning li a, .ul--lightning ul li a {
  color: #212529;
  text-decoration: underline;
}
ul.lightning li em, .ul--lightning ul li em {
  font-style: normal;
}
.block--padding ul.lightning, .block--padding .ul--lightning ul {
  margin-bottom: 38px;
}

.ul--lightning--blue ul li {
  background-image: url("/_resources/themes/topsystems/images/ico-flash-blue.svg");
}

.complete__table--header {
  display: none;
  padding: 5px;
  color: #fff;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
  background-color: #2f97da;
  text-align: center;
  border: 1px solid #808080;
}

.complete__table--row {
  display: flex;
  flex-direction: column;
}
.complete__table--row .complete--property {
  display: flex;
  flex-direction: column;
}
@media (max-width: 991.98px) {
  .complete__table--row .complete--property {
    padding: 10px;
    align-items: flex-start;
  }
}
@media (min-width: 768px) {
  .complete__table--row .complete--property {
    flex-direction: row;
  }
}
@media (max-width: 991.98px) {
  .complete__table--row .complete--property.button--right {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.complete__table--row .complete--property label {
  white-space: nowrap;
}
@media (min-width: 768px) {
  .complete__table--row .complete--property label {
    flex-basis: 145px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media (min-width: 992px) {
  .complete__table--row .complete--property label {
    display: none;
  }
}
.complete__table--row .complete--property div {
  display: flex;
}
@media (min-width: 992px) {
  .complete__table--row .complete--property div {
    align-items: center;
  }
}
.complete__table--row:nth-child(even) {
  background-color: rgba(47, 151, 218, 0.2);
}
.complete__table--row > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 992px) {
  .complete__table--row > div {
    height: 50px;
  }
}
.complete__table--row input[type=text],
.complete__table--row input[type=number] {
  display: inline-block;
  border-radius: 0;
  background: transparent;
  border: none;
  height: 30px;
  line-height: 1;
  padding: 10px;
}
.complete__table--row input[type=text]:hover {
  background-color: rgba(238, 238, 238, 0.4);
}
.complete__table--row input[type=number] {
  border-bottom: 1px solid black;
  text-align: center;
  width: 75px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 5px 10px;
  margin: 0 10px;
  -webkit-appearance: textfield;
}

@media (min-width: 992px) {
  .complete__table--header, .complete__table--row {
    display: grid;
    grid-template-columns: 20px 75px 1fr 200px 1fr 320px;
  }
}
.complete__table--header div:nth-of-type(1), .complete__table--row div:nth-of-type(1) {
  -ms-grid-column: 1;
}
.complete__table--header div:nth-of-type(2), .complete__table--row div:nth-of-type(2) {
  -ms-grid-column: 2;
}
.complete__table--header div:nth-of-type(3), .complete__table--row div:nth-of-type(3) {
  -ms-grid-column: 3;
}
.complete__table--header div:nth-of-type(4), .complete__table--row div:nth-of-type(4) {
  -ms-grid-column: 4;
}
.complete__table--header div:nth-of-type(5), .complete__table--row div:nth-of-type(5) {
  -ms-grid-column: 5;
}
.complete__table--header div:nth-of-type(6), .complete__table--row div:nth-of-type(6) {
  -ms-grid-column: 6;
}

.complete__table--body {
  position: relative;
  border: 1px solid #808080;
}
@media (min-width: 992px) {
  .complete__table--body {
    border-top: none;
  }
}

.big__label label.left {
  margin-bottom: 20px;
}
.big__label label.left {
  font-size: 1.6875rem;
}
@media (min-width: 992px) {
  .big__label label.left {
    font-size: 2.025rem;
  }
}
@media (min-width: 1200px) {
  .big__label label.left {
    font-size: 2.53125rem;
  }
}
@media (min-width: 1660px) {
  .big__label label.left {
    font-size: 3.375rem;
  }
}

.option__labels {
  width: 100% !important;
}
.option__labels .info__icon {
  display: inline-block;
  cursor: help;
  position: relative;
  flex-grow: 1;
  margin-left: 10px;
}
.option__labels .info__icon:before {
  content: "i";
  display: block;
  font-weight: bold;
  text-align: center;
  font-family: "Courier New", monospace;
  font-size: 14px;
  line-height: 18px;
  border: 2px solid #2f97da;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}
.option__labels .info__label {
  right: 10px;
  top: 30px;
  background-color: #FFF;
  border: 1px solid #2f97da;
  border-radius: 8px;
  padding: 10px;
  z-index: 1;
  font-size: 14px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .option__labels .info__label {
    padding: 5px;
    width: 180px;
    position: absolute;
  }
}
@media (min-width: 992px) {
  .option__labels .info__label {
    width: 250px;
  }
}
@media (min-width: 1200px) {
  .option__labels .info__label {
    padding: 10px;
    width: 100%;
  }
}
.option__labels .form-check-label {
  line-height: 26px;
}
@media (min-width: 992px) {
  .option__labels .form-check-label {
    min-height: 52px;
  }
}

form .field ul.optionset.option__labels.option__solutions li + li {
  margin-top: 10px;
}
@media (min-width: 992px) {
  form .field ul.optionset.option__labels.option__solutions li + li {
    margin-top: 42px;
  }
}

.faq__item {
  display: block;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid #212529;
  position: relative;
}
.faq__item:first-of-type {
  border-top: 1px solid #212529;
}
.faq__item + .faq__item {
  margin-top: 0;
}
.faq__item .faq__inner {
  padding: 30px 30px 30px 0;
}
@media (min-width: 992px) {
  .faq__item .faq__inner {
    padding: 30px 100px 30px 0;
  }
}
.faq__item h2 {
  margin-bottom: 0;
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .faq__item h2 {
    font-size: 2rem;
  }
}
.faq__item h2 a {
  display: block;
  color: #212529;
  padding: 0 18px 0 0;
}
.faq__item h2 a:hover {
  text-decoration: none;
}
.faq__item h2 a:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 25px;
  top: 40px;
  margin-top: -5px;
  content: "+";
  width: 10px;
  height: 10px;
  z-index: 9;
}
.faq__item .faq__answer {
  display: none;
  padding-top: 10px;
}
.faq__item .faq__answer p:last-child {
  margin-bottom: 0;
}
.faq__item.opened .faq__answer {
  display: block;
}
.faq__item.opening h2 a:after {
  content: "-";
  transform: scale(1.5);
}

.event__filters a.active {
  color: #212529;
}

.single__event h3 {
  font-size: 18px;
  line-height: 1.1;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 20px;
}
.single__event h3 a {
  color: #2f97da !important;
}

.fit--image {
  position: relative;
}
.fit--image .dates, .fit--image .training__status {
  color: #fff;
  background-color: #1C6AC3;
  padding: 8px;
  font-family: "Avenir Black", "Avenir", Arial, Helvetica, sans-serif;
}
.fit--image .dates.abs {
  position: absolute;
  right: 0;
  bottom: 0;
}
.fit--image .training__status.abs {
  position: absolute;
  left: 0;
  top: 0;
}

.event__results {
  font-size: 16px;
}

.training__filters .hide__label label.left {
  display: none;
}

.training__results .training__icons .icon--small {
  color: #2f97da;
}

.training__icons {
  margin-bottom: 20px;
}
.training__icons .icon--small img {
  vertical-align: middle;
  width: 20px;
  height: 26px;
}
.training__icons .icon--small span {
  display: inline-block;
  width: 24px;
  height: 28px;
}

.single__job h3 {
  font-size: 18px;
  line-height: 1.1;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 20px;
}
.single__job h3 a {
  color: #2f97da !important;
}