.iframe__holder {
  /* purgecss ignore current */
  margin-bottom: 0;
  padding-bottom: 0;

  iframe {
    display: block;
    width: 100%;
  }
}

.opening__times {
  tr {
    td:first-child {
      padding-right: 20px;
      @include media-breakpoint-up(md) {
        padding-right: 50px;
      }
    }
    td:nth-child(2) {
      white-space: nowrap;
    }
  }
}

#ContactForm_ContactForm {
  input[type="submit"], .btn-toolbar .action {
    width: 100%;
  }
}
