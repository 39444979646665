.faq__item {
  display: block;
  //background-color: $white;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid $black;
  position: relative;

  &:first-of-type {
    border-top: 1px solid $black;
  }

  + .faq__item {
    margin-top: 0;
  }

  .faq__inner {
    padding: 30px 30px 30px 0;

    @include media-breakpoint-up(lg) {
      padding: 30px 100px 30px 0;
    }
  }

  h2 {
    margin-bottom: 0;
    font-size: $h4-font-size;

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
    }

    a {
      display: block;
      color: $black;
      padding: 0 18px 0 0;

      &:hover {
        text-decoration: none;
      }

      &:after {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 25px;
        top: 40px;
        margin-top: -5px;
        content: '+';
        width: 10px;
        height: 10px;
        z-index: 9;
      }
    }
  }

  .faq__answer {
    display: none;
    padding-top: 10px;
    //padding-bottom: 20px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &.opened {
    .faq__answer {
      display: block;
    }
  }

  &.opening {
    h2 {
      a {
        &:after {
          content: '-';
          transform: scale(1.5);
        }
      }
    }
  }
}

