.slider__nav {
  .slick-slide {
    margin: 0 10px;
  }

  .slick-list {
    margin: 0 -10px;
  }

  .slider__gallery[data-browse="true"] + & {
    margin-left: 15px;
    margin-right: 15px;

    @include media-breakpoint-up(lg) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

.slider__gallery {
  position: relative;

  &[data-browse="true"] {
    margin-left: 15px;
    margin-right: 15px;

    @include media-breakpoint-up(lg) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  &.slick-dotted.slick-slider {
    margin-bottom: 50px;
  }

  &[data-multiple] {
    .slick-list {
      margin: 0 -#{$grid-gutter-width / 2};
    }

    .slick-slide {
      margin: 0 #{$grid-gutter-width / 2};
    }
  }

}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 5;
  overflow: hidden;
  text-indent: -999px;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  height: 15px;
  width: 15px;

  border-left: 2px solid $blueShadeDark;
  border-bottom: 2px solid $blueShadeDark;
  display: block;

  &:active {
    background-color: transparent;
  }

  &.slick-prev {
    left: -20px;
    @include media-breakpoint-up(lg) {
      left: -40px;
    }

    transform: rotate(45deg);
  }

  &.slick-next {
    right: -20px;
    @include media-breakpoint-up(lg) {
      right: -40px;
    }
    transform: rotate(225deg);
  }
}

.slick-dots {
  bottom: -40px;

  li {
    button {
      &:before {
        // the default font
        display: none;
      }

      &:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 3px solid $blueShadeDark;
        border-radius: 10px;
        background-color: $white;
      }
    }

    &.slick-active {
      button {
        &:after {
          background-color: $blueShadeDark;
        }
      }
    }

  }
}


.slider__carousel {
  position: relative;

  //.slick-arrow {
  //  position: absolute;
  //  top: 50%;
  //  margin-top: -40px;
  //  z-index: 5;
  //  overflow: hidden;
  //  text-indent: -999px;
  //  outline: none;
  //  box-shadow: none;
  //  background-color: transparent;
  //  border: 0;
  //  padding: 0;
  //  height: 100px;
  //  width: 30px;
  //
  //  &:before, &:after {
  //    position: absolute;
  //    content: '';
  //    border-left: 2px solid $blueShadeDark;
  //    display: block;
  //    width: 3px;
  //    height: 30px;
  //    top: 0;
  //  }
  //
  //  &:active {
  //    background-color: transparent;
  //  }
  //
  //  &.slick-prev {
  //    left: 20px;
  //
  //    &:before {
  //      left: 10px;
  //      top: 48px;
  //      transform: rotate(160deg);
  //    }
  //
  //    &:after {
  //      left: 10px;
  //      top: 2px;
  //      transform: rotate(-160deg);
  //    }
  //  }
  //
  //  &.slick-next {
  //    right: 20px;
  //
  //    &:before {
  //      left: 10px;
  //      top: 2px;
  //      transform: rotate(160deg);
  //    }
  //
  //    &:after {
  //      left: 10px;
  //      top: 48px;
  //      transform: rotate(-160deg);
  //    }
  //  }
  //
  //  // slider is positioned in container
  //  .container & {
  //    &.slick-prev {
  //      left: -15px;
  //      @include media-breakpoint-up(sm) {
  //        left: -25px;
  //      }
  //      @include media-breakpoint-up(md) {
  //        left: -50px;
  //      }
  //    }
  //
  //    &.slick-next {
  //      right: -15px;
  //      @include media-breakpoint-up(sm) {
  //        right: -25px;
  //      }
  //      @include media-breakpoint-up(md) {
  //        right: -50px;
  //      }
  //    }
  //  }
  //}

  .slick-track {
    display: flex;
  }

  .slick-track .slick-slide {
    display: flex;
    height: auto;
    justify-self: center;
  }

  .slick-slide {
    flex-grow: 1;
    margin-bottom: 0;

    > div {
      &,
      .simple-slide,
      .simple-slide .row,
      .simple-slide .row > div {
        @include media-breakpoint-up(md) {
          width: 100%;
          height: 100%;
        }
      }
    }

    .brand__content {
      margin-bottom: 20px;
    }

    .btn {
      margin-right: auto;
      margin-top: auto;
      margin-bottom: 3px;
    }

    .container & {
      @include media-breakpoint-only(xs) {
        padding-left: 15px;
        padding-right: 15px;
      }
      @include media-breakpoint-only(sm) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }


  &.slider--grayscale {
    .slick-arrow {
      &:before, &:after {
        border-left: 2px solid $black;
      }
    }
  }

  &.slider--huge-images {
    .slick-arrow {
      &.slick-next,
      &.slick-prev {
        @include media-breakpoint-down(md) {
          top: 150px;
          margin-top: -50px;
        }
      }


      &:before, &:after {
        border-left: 2px solid $white;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      }

      &.slick-prev {
        @include media-breakpoint-up(lg) {
          &:before, &:after {
            border-left: 2px solid $black;
          }
        }
      }
    }
  }

}

.slider__content {
  margin-bottom: 10px;
}

.slider-navigation {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include media-breakpoint-down(md) {
    padding-bottom: 10px;
    justify-content: flex-start;
    //white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: scroll;
    a {
      flex-shrink: 0;
    }
  }

  &.rows-mobile {
    overflow-x: hidden;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  a {
    display: flex;
    text-decoration: none;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;

    @include media-breakpoint-up(md) {
      min-height: 50px;

      &.regular,
      &.quarter {
        font-size: 14px;
        margin-right: 30px;
        margin-bottom: 20px;
        width: auto;

        &:last-of-type {
          margin-right: 0;
        }
      }
      &.quarter {
        flex-shrink: 1;
        flex-basis: calc(20% - 30px);
      }
    }

    //+ a {
    //  margin-left: 30px;
    //}

    img {
      display: block;
      width: auto;
      max-height: 50px;
      vertical-align: middle;
    }

    &.active {
      border-bottom: 2px solid $blueShadeDark
    }
  }

  &.rows-mobile {
    @include media-breakpoint-down(sm) {
      a {
        margin-left: 0;

        + a {
          margin-top: 30px;
        }
      }
    }
  }
}

.slider--huge-image {
  background-color: $gray;

  .simple-slide {
    min-height: 600px;

    display: flex !important;
    align-items: center;
    flex-direction: column;
    padding: 0 0 60px 0;

    @include media-breakpoint-up(md) {
      padding: 60px 0;
      flex-direction: row;
    }
  }

  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 1.3rem;
    }
    h3 {
      font-size: 1.1rem;
    }
  }

  .filled-image {
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: cover;
    width: 100%;
    height: 300px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      height: auto;
      width: auto;
      position: absolute;
      left: 50%;
      right: 0;
      top: 0;
      bottom: 0;

    }
  }

}


.slider--has-shadows {
  .slick-slide {
    padding: 25px 0;
  }
}

