[v-cloak] {
  display: none
}

.quote__support {
  display: flex;
  //margin-top: 20px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    width: 275px;
  }

  img {
    display: inline-block;
    width: 40px;
    height: auto;
    margin-right: 20px;
  }
}

#quoteApp {
  position: relative;

  &.loading {
    &:before {
      z-index: 100;
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }

  }
}

body.Quote {
  &.index,
  &.complete {
    background-color: #eceded;
  }

  #mainContainer {
    padding-bottom: 0;
  }

  .btn-toolbar {
    .action {
      position: relative;
      font-family: $h1-font-family;
      background-color: $corporateRed;
    }
  }

  .page__centered-block--content {
    min-height: 0;
  }

  .page__centered-block--small {
    text-align: left;
    width: 50%;
    min-width: 300px;
    margin: 0 auto;
    max-width: 475px;

    ol {
      margin: 0 auto;
      width: fit-content;
    }
  }

  .form--alert {
    display: block;
    position: absolute;
    left: 22px;
    bottom: -22px;
    font-size: 16px;
    line-height: 16px;
    height: 16px;
  }

  textarea.vuetextarea {
    margin: 0;
    display: block;
  }

  .field.vuetextarea {
    margin-bottom: 20px;
  }

  #QuoteForm_QuoteForm_Name_Holder {
    @include media-breakpoint-up(md) {
      float: left;
      width: calc(50% - #{$grid-gutter-width / 2});
    }

    @include media-breakpoint-up(xxl) {
      width: auto;
      float: none;
    }
  }

  #QuoteForm_QuoteForm_Company_Holder {
    @include media-breakpoint-up(md) {
      margin-top: 0;
      float: right;
      width: calc(50% - #{$grid-gutter-width / 2});
    }

    @include media-breakpoint-up(xxl) {
      margin-top: 30px;
      width: auto;
      float: none;
    }
  }

  #QuoteForm_QuoteForm_Email_Holder {
    @include media-breakpoint-up(md) {
      margin-top: 30px;
      margin-bottom: 30px;
      float: left;
      width: calc(50% - #{$grid-gutter-width / 2});
    }

    @include media-breakpoint-up(xxl) {
      width: auto;
      float: none;
    }
  }

  #QuoteForm_QuoteForm_Phone_Holder {
    @include media-breakpoint-up(md) {
      margin-top: 30px;
      margin-bottom: 30px;
      float: right;
      width: calc(50% - #{$grid-gutter-width / 2});
    }

    @include media-breakpoint-up(xxl) {
      width: auto;
      float: none;
    }
  }

  #QuoteForm_QuoteForm_Message_Holder {
    @include media-breakpoint-up(md) {
      clear: both;
    }

    @include media-breakpoint-up(xxl) {
    }
  }

  #QuoteForm_QuoteForm_Referrer_Holder {
    position: relative;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  #QuoteForm_QuoteForm_ReferrerOther_Holder {
    margin-top: -20px !important;
    margin-bottom: 20px;
  }

  .btn-toolbar {
    flex-direction: column;
    align-items: start;

    .action {
      width: 100%;
      background-color: $corporateRed;
      background-image: none;
      border: $outline-width solid $corporateRed;
    }

    @include media-breakpoint-up(xxl) {
      width: 33%;
      padding-left: #{$grid-gutter-width / 2};
      //padding-right: #{$grid-gutter-width / 2};
      transform: translateY(-30px);
      margin-left: auto;
    }
    @include media-breakpoint-up(xxxl) {
      transform: translateY(-5px);
    }

    .form__under {
      display: block;
      margin-top: 20px;
    }

  }
}


.quote__indicator-text {
  @include media-breakpoint-up(lg) {
    margin-right: 30px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.quote__indicator {
  display: block;
  border: 3px solid $blue;
  border-radius: 16px;
  width: 150px;
  height: 60px;
  //margin-bottom: 60px;
  position: relative;

  &:before {
    display: block;
    content: "";
    height: 24px;
    position: absolute;
    top: 15px;
    border-radius: 0 9px 9px 0;
    background-color: #2f97da;
    width: 13px;
    right: -13px;
  }
}

.quote__indicator--frame {
  background: white;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  border: 3px solid white;
  overflow: hidden;
}

.progress__bar {
  display: block;
  background-color: $blue;
  height: 100%;
  transition: width 500ms ease;
}

.progress__number {
  font-weight: bold;
  font-family: $h1-font-family;
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  flex-direction: column;
  display: none;
  font-size: 24px;
  right: -90px;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

ul.lightning, .ul--lightning ul {
  padding-left: 0;
  list-style: none;

  li {
    line-height: 28px;
    background: url('/_resources/themes/topsystems/images/ico-flash-black.svg') 0 3px no-repeat;
    background-size: 16px auto;
    padding-left: 25px;

    a {
      color: $black;
      text-decoration: underline;
    }

    em {
      font-style: normal;
    }
  }

  .block--padding & {
    margin-bottom: 38px;
  }
}

.ul--lightning--blue ul {
  li {
    background-image: url('/_resources/themes/topsystems/images/ico-flash-blue.svg');
  }
}

.complete__table--header {
  display: none;
  padding: 5px;
  color: $white;
  font-family: $headings-font-family;
  background-color: $blue;
  text-align: center;
  border: 1px solid #808080;
}

.complete__table--row {
  display: flex;
  flex-direction: column;

  .complete--property {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(md) {
      padding: 10px;
      align-items: flex-start;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &.button--right {
      @include media-breakpoint-down(md) {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    label {
      white-space: nowrap;
      @include media-breakpoint-up(md) {
        flex-basis: 145px;
        flex-grow: 0;
        flex-shrink: 0;
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    div {
      display: flex;
      @include media-breakpoint-up(lg) {
        align-items: center;
        //font-size: 12px;
      }
    }
  }

  &:nth-child(even) {
    background-color: transparentize($corporateBlue, 0.8);
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include media-breakpoint-up(lg) {
      height: 50px;
    }
  }

  input[type=text],
  input[type=number] {
    display: inline-block;
    border-radius: 0;
    background: transparent;
    border: none;
    height: 30px;
    line-height: 1;
    padding: 10px;
  }

  input[type=text]:hover {
    background-color: rgba(238, 238, 238, 0.4)
  }

  input[type=number] {
    border-bottom: 1px solid black;
    text-align: center;
    width: 75px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 5px 10px;
    margin: 0 10px;
    -webkit-appearance: textfield;
  }

}


.complete__table--header, .complete__table--row {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 20px 75px 1fr 200px 1fr 320px;
  }

  div:nth-of-type(1) {
    -ms-grid-column: 1;
  }

  div:nth-of-type(2) {
    -ms-grid-column: 2;
  }

  div:nth-of-type(3) {
    -ms-grid-column: 3;
  }

  div:nth-of-type(4) {
    -ms-grid-column: 4;
  }

  div:nth-of-type(5) {
    -ms-grid-column: 5;
  }

  div:nth-of-type(6) {
    -ms-grid-column: 6;
  }
}

.complete__table--body {
  position: relative;
  border: 1px solid #808080;
  @include media-breakpoint-up(lg) {
    border-top: none;
  }
}

.big__label {
  label.left {
    @include growingh2;
    margin-bottom: 20px;
  }
}

.option__labels {
  width: 100% !important;

  .info__option {
    //@include media-breakpoint-up(md) {
    //  display: flex;
    //  width: 100%;
    //}
  }

  .info__icon {
    display: inline-block;
    cursor: help;
    position: relative;
    flex-grow: 1;
    margin-left: 10px;

    &:before {
      content: 'i';
      display: block;
      font-weight: bold;
      text-align: center;
      font-family: "Courier New", monospace;
      font-size: 14px;
      line-height: 18px;
      border: 2px solid $blue;
      border-radius: 100%;
      height: 20px;
      width: 20px;
    }
  }

  .info__label {
    right: 10px;
    top: 30px;
    background-color: #FFF;
    border: 1px solid $blue;
    border-radius: 8px;
    padding: 10px;
    z-index: 1;
    font-size: 14px;
    line-height: 18px;

    @include media-breakpoint-up(md) {
      //left: 30px;
      padding: 5px;
      width: 180px;
      position: absolute;
    }

    @include media-breakpoint-up(lg) {
      width: 250px;
    }

    @include media-breakpoint-up(xl) {
      padding: 10px;
      width: 100%;
    }
  }


  .form-check-label {
    line-height: 26px;
    @include media-breakpoint-up(lg) {
      min-height: 52px;
    }
  }
}

form .field ul.optionset.option__labels.option__solutions {
  li + li {
    margin-top: 10px;
    @include media-breakpoint-up(lg) {
      margin-top: 42px;
    }
  }
}

