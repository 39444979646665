.event__results {
  font-size: 16px;
}

.training__filters {
  .hide__label {
    label.left {
      display: none;
    }
  }
}

.training__results {
  .training__icons {
    .icon--small {
      color: $corporateBlue;
    }
  }
}

.training__icons {
  margin-bottom: 20px;

  .icon--small {
    img {
      vertical-align: middle;
      width: 20px;
      height: 26px;
    }

    span {
      display: inline-block;
      width: 24px;
      height: 28px;
    }
  }
}
