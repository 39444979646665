
.element {
  img {
    max-width: 100%;
  }
}

.dnadesign__elementallist__model__elementlist {
  padding: 20px 0;
}

.list-element__container {
  display: flex;

  .element {
    flex-basis: 50%;

    + .element {
      margin-left: 40px;
    }
  }
}

.inner-row,
.outer-row {
  padding: 20px 0;
  @include media-breakpoint-up(md) {
    padding: 40px 0;
  }
  @media (min-width: 576px) and (min-height: $desktop-height) {
    padding: 120px 0;
  }

  &.blue {
    color: $white;
    background-color: $blue;
  }

  &.gray, &.grey {
    background-color: $gray;
  }

  &.gray-stop-late, &.grey-stop-late {
    background-color: $gray;
    @include media-breakpoint-up(md) {
      background-color: transparent;
      background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 80%, transparent 80%, transparent 100%);
    }
  }

  &.gray-stop-early, &.grey-stop-early {
    background-color: $gray;
    @include media-breakpoint-up(md) {
      background-color: transparent;
      background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 65%, transparent 65%, transparent 100%);
    }
  }

  &.gray-stop-half, &.grey-stop-half {
    //background-color: transparent;
    @include media-breakpoint-up(md) {
      background-color: transparent;
      background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 50%, transparent 50%, transparent 100%);
    }
  }

  &.gray-stop-fixed, &.grey-stop-fixed {
    background-color: $gray;
    @include media-breakpoint-up(md) {
      background-color: transparent;
      background-image: linear-gradient(to bottom, #edeeee 0%, #edeeee 150px, transparent 150px, transparent 100%);
    }
  }

  &.padding-if-color-difference {
    padding-top: 0.25rem;

    .outer-row.bg-blue + &,
    .outer-row.bg-blue-slider + &,
    .outer-row.bg-blue-gray + & {
      padding: 20px 0;
      @include media-breakpoint-up(md) {
        padding: 40px 0;
      }
      @media (min-height: $desktop-height) {
        padding: 60px 0;
      }
    }

  }

}

div {
  &.bg-white + .color-difference {
    background-color: $gray !important;

    + .color-difference {
      background-color: $white !important;

      + .color-difference {
        background-color: $gray !important;

        + .color-difference {
          background-color: $white !important;

          + .color-difference {
            background-color: $gray !important;

            + .color-difference {
              background-color: $white !important;
            }
          }
        }
      }
    }
  }
}

.has-popout-gray {
  background-color: $gray;
  @include media-breakpoint-up(md) {
    background-color: transparent;
    background-image: linear-gradient(to right, transparent 0%, transparent 50%, #edeeee 50%, #edeeee 100%);
  }
}

.col--popout {
  @include media-breakpoint-up(md) {
    margin-right: -50px;
  }
  @include media-breakpoint-up(lg) {
    margin-right: -100px;
  }
  @include media-breakpoint-up(xl) {
    margin-right: -150px;
  }
  @include media-breakpoint-up(xxl) {
    margin-right: -420px;
  }
}

.popout {
  &.gray-right {
    background-color: $gray;
    @include media-breakpoint-up(md) {
      background-color: transparent;
      background-image: linear-gradient(to right, transparent 0%, transparent 100px, #edeeee 100px, #edeeee 100%);
    }
  }
}

.bg-blue {
  color: $white;
  background-color: $blue;
}

.bg-blue-slider {
  background-color: $blueSliderBackground;
}

.bg-gray, .bg-grey {
  background-color: $gray;
}

.top__block {
  ul {
    padding-left: 19px;
    line-height: 1.61em;
  }

  em {
    font-style: normal;
    text-decoration: underline;
  }

}

.shadow__block {
  box-shadow: 0 3px 99px rgba(0, 0, 0, 0.15);
}

.blue-header__block {
  box-shadow: 0 3px 99px rgba(0, 0, 0, 0.15);
}

.white__block {
  background-color: $white;
  box-shadow: 0 3px 99px rgba(0, 0, 0, 0.15);

  .slick-list & {
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15);
  }

  &, a:not(.btn) {
    color: $black;
  }
}

.h4--blue {
  h4 {
    color: $corporateBlue;
  }
}

.block--half-icon {
  .half-icon__holder {
    position: relative;
    margin-bottom: 50px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    .half-icon {
      height: 100px;
      width: 100px;
      background: radial-gradient(at 120% 50%, $corporateGradientStart 0%, $corporateGradientStop 100%);
      border-radius: 50px;
      margin-top: -50px;
      display: flex;
      position: absolute;
      left: 50%;
      margin-left: -50px;
      justify-content: center;
      align-items: center;
      z-index: 2;

      &.icon--right {
        @include media-breakpoint-up(md) {
          left: 100%;
          top: 50%;
        }
      }

      img {
        width: 70px;
        height: 60px;
        object-fit: contain;
      }

    }
  }

  .block--padding {
    &.half-icon__content {
      padding-top: 30px;
      @include media-breakpoint-up(md) {
        padding-top: 70px;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 90px;
      }
    }
  }
}

.border__block {
  padding: 3px;

  .border__block--header {
    color: $white;
    padding: 15px 20px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .border__block--inner {
    background-color: $white;
    padding: 20px 40px;
  }
}

.image__row {
  img {
    max-width: calc(100% + #{$grid-gutter-width});
    margin-left: -#{$grid-gutter-width / 2};
    margin-right: -#{$grid-gutter-width / 2};
  }
}

.block--padding {
  padding: 20px;

  @include media-breakpoint-up(lg) {
    padding: 30px;
  }

  @include media-breakpoint-up(xl) {
    padding: 60px;
  }

  &.block--narrow, .block--narrow & {
    padding: 20px;
    @include media-breakpoint-up(lg) {
      padding: 40px;
    }
  }

  &.block--wide, .block--wide & {
    @include media-breakpoint-up(xxl) {
      padding: 70px 100px;
    }
  }
}

.block--gray {
  background-color: $gray;

  a:not(.btn) {
    color: $black;
  }
}

.highlighted__block-holder {
  position: relative;

  &.highlighted__block--blue {
    color: $white;
    background-color: $corporateBlue;
  }
}

.contact__block--holder {
  .image__pull--left-right-side {
    min-height: 536px;
  }

  .block--padding {
    @include media-breakpoint-up(lg) {
      padding-left: 66px;
    }
  }

  .cover--image img {
    @include media-breakpoint-down(sm) {
      height: 200px;
    }
  }
}

.image__pull--left {
  position: absolute;
  left: 0;
  top: 0;

  @include media-breakpoint-down(md) {
    right: 0;
    height: 400px;
    max-width: none;
    padding-left: 0;
    padding-right: 0;

    .contact__block--holder & {
      height: 200px;
    }
  }

  img {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    left: -$grid-gutter-width / 2;
    bottom: 0;
  }
}

.image__pull--left-right-side {
  margin-top: 400px;

  .contact__block--holder & {
    margin-top: 200px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0 !important;
    margin-left: calc(50% - #{$grid-gutter-width});
  }

  @include media-breakpoint-up(xxl) {
    margin-right: -200px;
  }
}

.training-explanation {
  .image__pull--right-left-side {
    min-height: 430px;
  }
}

.image__pull--right {
  position: absolute;
  right: 0;
  top: 0;

  @include media-breakpoint-down(md) {
    left: 0;
    height: 400px;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  img {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    bottom: 0;
    img {
      margin-left: $grid-gutter-width / 2;
    }
  }
}

.image__pull--right-left-side {
  margin-top: 400px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-right: calc(50% - #{$grid-gutter-width});
  }

  //@include media-breakpoint-up(xxl) {
  //  margin-left: -200px;
  //}
}
