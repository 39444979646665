.pdf__download_block {
  display: none;

  &.shown {
    display: block;
  }
}

.showcase__table {
  @include media-breakpoint-down(md) {
    td, th {
      display: block;
    }
  }
}

.slick-slide {
  .single__project {
    height: 100%;
  }
}

.project__logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto 0;
  @include media-breakpoint-up(md) {
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  img {
    width: 225px;
    height: 50px;
    object-fit: contain;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
      height: 75px;
      + img {
        margin-left: 40px;
      }
    }
  }

  &.project__logos--small {
    width: 100%;
    max-width: none;
    justify-content: space-between;

    img {
      width: calc(50% - 10px);
      height: 40px;
      margin-bottom: 20px;

      + img {
        margin-left: 0;
      }
    }
  }
}

.project__filters {
  form {
    position: relative;
  }

  .btn-toolbar {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(100%) translateX(#{$grid-gutter-width / 2}) translateY(-100%) translateY(-60px);
    }

    @include media-breakpoint-up(xl) {
      right: 33%;
    }
  }

}

.step__item {
  display: flex;
  position: relative;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .icon {
    flex-shrink: 0;
    width: 44px;
    margin: 0 43px 20px 0;
  }

  + .step__item {
    margin-top: 45px;
  }

  div p:last-of-type {
    margin-bottom: 0;
  }

  .step__item--line {
    position: relative;


    .icon--small-40 {
      margin-top: -3px;
      @include media-breakpoint-up(md) {
        margin-top: -5px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -3px;
      }
    }

    // only mobiel
    // middle line
    //&:after {
    //  content: '';
    //  border-right: 2px solid $blue;
    //  position: absolute;
    //  left: 50%;
    //  margin-left: -1px;
    //  top: 50px;
    //  bottom: 0;
    //  z-index: 1;
    //}
  }
}

.project__details {
  position: relative;
  border: 1px solid $white;
}

.push--over {
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 0;
  transform: translateY(-50%);

  .btn-primary {
    &:hover {
      background-color: #FFF;
    }
  }

  .btn {
    @include media-breakpoint-down(sm) {
      font-size: $btn-font-size-sm;
      padding-left: $btn-padding-x-sm/1.5;
      padding-right: $btn-padding-x-sm * 1.5 !important;
      padding-top: $btn-padding-y-sm;
      padding-bottom: $btn-padding-y-sm;

      &:after {
        right: $btn-padding-x-sm * 0.75;
      }
    }

  }
}
