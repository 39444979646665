.block__column {
  display: flex;
  flex-direction: column;
  height: 100%;

  .block__column--content {
    flex-grow: 1;
    padding-bottom: 62px;
    position: relative;

    a.btn:last-child {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.arrow--up {
  display: block;
  position: relative;
  width: 48px;
  height: 48px;
  text-indent: -999px;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-top: 2px solid $primary;
    border-right: 2px solid $primary;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -5px;
    transform: rotate(-45deg);
  }
}

.process-step {
  position: relative;
  display: block;
  //background-color: $white;
  width: 100%;
  padding: 0 20px 0 0;
  word-break: break-word;
  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }

  .border__holder {
    border-bottom: 1px solid $black;
  }

  .process-step__item--line {
    position: relative;
    padding-top: 10px;

    &:after {
      content: '';
      border-right: 2px solid $blue;
      position: absolute;
      left: 50%;
      margin-left: -1px;
      top: 0;
      bottom: 0;
      z-index: 1;
    }

    &.first {
      &:after {
        top: 50px;
      }
    }

    &.last {
      &:after {
        top: 0;
        bottom: calc(100% - 50px);
      }
    }
  }

  &:first-of-type {
    .border__holder {
      border-top: 1px solid $black;
    }
  }

  + .process-step__item {
    margin-top: 0;
  }

  .process-step__inner {
    padding: 20px 0 20px 0;
    @include media-breakpoint-up(md) {
      padding: 37px 100px 37px 0;
    }
  }

  .process-step__icon {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 6px solid $corporateBlue;
    border-radius: 50px;
    margin: 0 auto;
    z-index: 5;
    width: 60px;
    height: 60px;

    @include media-breakpoint-down(md) {
      position: absolute;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px;

      &.icon--small-40 img {
        height: 34px;
      }
    }
    @include media-breakpoint-up(lg) {
      border: 10px solid $corporateBlue;
      width: 90px;
      height: 90px;
    }
  }


  h3 {
    margin-bottom: 0;
    font-size: $h4-font-size;
    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
    }

    a {
      display: block;
      color: $black;
      padding: 0 20px 0 0;
      @include media-breakpoint-up(md) {
        padding: 0 25px 0 0;
      }

      &:hover {
        text-decoration: none;
      }

      &:after {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -5px;
        content: '+';
        width: 10px;
        height: 10px;
        z-index: 9;
        right: 15px;
        top: 35px;
        @include media-breakpoint-up(md) {
          right: 25px;
          top: 40px;
        }
      }
    }
  }

  .process-step__more {
    display: none;
    padding-top: 10px;
    padding-bottom: 100px;

    a.btn {
      visibility: hidden;
      position: absolute;
      left: -20px;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
      a.btn {
        position: relative;
        left: auto;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &.opened {
    .process-step__more {
      display: block;
    }
  }

  &.opened.opening {
    .process-step__more {
      a.btn {
        visibility: visible;
      }
    }
  }

  &.opening {
    h3 {
      a {
        &:after {
          content: '-';
        }
      }
    }
  }
}

.align-process-step {
  margin-left: 20px;
  @include media-breakpoint-up(lg) {
    margin-left: 26px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: 40px;
  }
  @include media-breakpoint-up(xxl) {
    margin-left: 78px;
  }
  @include media-breakpoint-up(xxxl) {
    margin-left: 90px;
  }
}
