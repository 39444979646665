@font-face {
  font-family: 'Avenir Black';
  src: local('Avenir Black'), local('Avenir-Black'),
  url('/_resources/themes/topsystems/webfonts/avenir-black.woff2') format('woff2'),
  url('/_resources/themes/topsystems/webfonts/avenir-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
//
//@font-face {
//  font-family: 'Avenir Medium';
//  src: local('Avenir Medium'), local('Avenir-Medium'),
//  url('/_resources/themes/topsystems/webfonts/avenir-medium-webfont.woff2') format('woff2'),
//  url('/_resources/themes/topsystems/webfonts/avenir-medium-webfont.woff') format('woff');
//  font-weight: 700;
//  font-style: normal;
//}

@font-face {
  font-family: 'Avenir Light';
  src: local('Avenir Light'), local('Avenir-Light'),
  url('/_resources/themes/topsystems/webfonts/avenir-light.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), local('Avenir Regular'), local('Avenir-Regular'), local('Avenir-Roman'),
  url('/_resources/themes/topsystems/webfonts/avenir-roman.woff2') format('woff2'),
  url('/_resources/themes/topsystems/webfonts/avenir-roman.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir Black'), local('Avenir-Black'),
  url('/_resources/themes/topsystems/webfonts/avenir-black.woff2') format('woff2'),
  url('/_resources/themes/topsystems/webfonts/avenir-black.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
