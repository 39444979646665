.jump-navigation {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  a {
    text-decoration: none;
    padding-bottom: 10px;
    @include h1font;

    @include media-breakpoint-up(md) {
      &.quarter {
        flex-basis: 25%;
      }
    }

    + a {
      margin-top: 0;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 50px;
      }
    }
  }
}

.even-odd__block {
  position: relative;
  background-color: $white;

  //&.first, &.last {
  //  background-color: #edeeee;
  //}
}

.even-odd__block--padding {
  padding: 40px 0;
  @include media-breakpoint-up(md) {
    padding: 40px;
  }
}

.even-odd__block--content-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;

  @include media-breakpoint-up(lg) {
    min-height: 500px;
  }
}

.even-odd__block--image {
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  width: 100%;
  height: 300px;

  @include media-breakpoint-up(md) {
    height: auto;
    width: auto;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;

    .even & {
      left: 0;
      right: 50%;
    }
  }
}

