.specialist,
.specialist__advice {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.specialist--compact,
.specialist__advice {
  align-items: center;

  .specialist__image {
    flex-shrink: 0;
    img {
      width: 80px;
      height: auto;
      border-radius: 40px;
      border: 1px solid $gray;
    }
  }
}

.specialist__advice {
  .specialist--name {
    font-size: $font-size-base * 0.8;
  }
}

.specialist__content {
  margin-left: 20px;
  @include media-breakpoint-up(lg) {
    margin-left: 40px;
  }
}

.specialist--extended {
  color: $white;
  background-color: $blue;
  margin-top: 3rem !important;
  padding: 1rem 1.25rem 1.25rem 1.25rem;
  font-size: $font-size-base * .75;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  .specialist__content {
    margin-left: 0;
    @include media-breakpoint-up(sm) {
      margin-left: 40px;
    }
  }

  a {
    color: $white;
  }

  .h6 {
    font-size: $font-size-base * .85;
  }

  .specialist__image {
    margin-top: -3rem;
  }
}

.specialist__logo {
  img {
    height: 68px;
  }
}

.specialist--name {
  font-weight: bold;
}