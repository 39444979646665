.square__item {
  display: block;
}

//.square__item--image {
//  padding-top: 100%;
//  background-size: cover;
//}

.square__item--link {
  color: $black;

  &, &:hover {
    color: $black;
    text-decoration: none;
  }
}

.square__item--title {
  font-size: 16px;
  margin-bottom: 0;
}

.square__item--text {
  padding: 15px 40px 40px 40px;
}

.square__item--footer {
  white-space: normal;
  @include media-breakpoint-up(sm) {
    white-space: nowrap;
  }

  @include media-breakpoint-up(md) {
    white-space: normal;
  }
  @include media-breakpoint-up(xxl) {
    white-space: nowrap;
  }
}
