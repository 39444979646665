.md-border-right-black {
  @include media-breakpoint-up(md) {
    border-right: 1px solid black;
  }
}

.md-border-left-black {
  @include media-breakpoint-up(md) {
    border-left: 1px solid black;
  }
}

.mobile-border-top-black {
  @include media-breakpoint-down(sm) {
    padding-top: 10px;
    border-top: 1px solid black;
  }
}

.mobile-border-bottom-black {
  @include media-breakpoint-down(sm) {
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }
}

.even-odd__square {
  position: relative;
  background-color: $white;

  &.even {
    background-color: #edeeee;
  }
}

.square__blocks {
  .square__block {
    background-color: $white;

    &.last {
      background-color: #edeeee;
    }
  }

  .row.even {
    @include media-breakpoint-up(md) {
      .square__block {
        background-color: #edeeee;

        &.last {
          background-color: $white;
        }
      }
    }
  }
}

.square__block--content-holder {
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.square__block--padding {
  padding: 40px 40px;
  @include media-breakpoint-up(md) {
    padding: 80px 40px;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 40px;
  }
}

.history__item {
  position: relative;
  padding-bottom: 100px;

  // only mobiel
  @include media-breakpoint-only(xs) {
    &:after {
      content: '';
      border-right: 2px solid $blue;
      position: absolute;
      left: 50%;
      margin-left: -1px;
      height: 90px;
      bottom: 10px;
      z-index: 1;
    }

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid $blue;
      background-color: #FFF;
      position: absolute;
      border-radius: 10px;
      left: 50%;
      margin-left: -10px;
      bottom: 10px;
      z-index: 2;
    }

    &.last {
      &:after {
        bottom: 0;
        height: 100px;
      }

      &:before {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: 75px;
    margin-right: 75px;
  }

  @include media-breakpoint-up(sm) {
    // middle line
    &:after {
      content: '';
      border-right: 2px solid $blue;
      position: absolute;
      left: 50%;
      margin-left: -1px;
      top: 0;
      bottom: 0;
      z-index: 1;
    }

    // dot
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid $blue;
      background-color: #FFF;
      position: absolute;
      border-radius: 10px;
      left: 50%;
      margin-left: -10px;
      top: 0;
      bottom: 0;
      z-index: 2;
    }

    &.first {
      &:after {
        top: calc(50% - 50px); // account for 100px padding bottom
      }
    }
    &:before {
      top: calc(50% - 60px); // account for 100px padding bottom
    }
  }
}

.history__item--col {
  @include media-breakpoint-up(sm) {
    &:nth-child(1) {
      padding-right: 50px;
    }

    &:nth-child(2) {
      padding-left: 50px;
    }
  }
  @include media-breakpoint-up(md) {
    &:nth-child(1) {
      padding-right: 100px;
    }

    &:nth-child(2) {
      padding-left: 100px;
    }
  }
}

.history__image--holder {
  display: flex;
  justify-content: center;
  margin: auto;
  //@include media-breakpoint-up(md) {
  //  padding: 0 100px;
  //}

  &:after {
    content: '';
    position: absolute;
  }

  img {
    width: auto;
    z-index: 3;
  }
}

@include media-breakpoint-up(sm) {
  .history__image--left {
    position: relative;

    &:after {
      content: '';
      border-top: 2px solid $blue;
      position: absolute;
      right: 0;
      left: 50%;
      top: 50%;
      margin-top: -1px;
      z-index: 1;
    }
  }

  .history__image--right {
    position: relative;

    &:after {
      content: '';
      border-top: 2px solid $blue;
      position: absolute;
      left: 0;
      right: 50%;
      top: 50%;
      margin-top: -1px;
      z-index: 1;
    }
  }
}

.history__year--holder {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-up(sm) {
    min-height: 300px;
  }
}

.history__year--title {
  color: $blue;
}

.history__year--content {
  margin-bottom: 20px;
  font-size: $font-size-sm;
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }
}

.history__item {
  .history__image--holder {
    order: 1;
  }

  .history__year--holder {
    order: 0;
  }

  @include media-breakpoint-up(sm) {
    .history__image--holder, .history__year--holder {
      order: initial;
    }
  }
}

