.brand__content {
  font-size: 16px;
  //h4, h5, h6 {
  //}
}

.image--holder {
  img {
    flex-shrink: 0;
  }
}